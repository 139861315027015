import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteEmployee, getAllEmployees } from '../../actions/employeeActions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEllipsisV,
  faEdit,
  faTrash,
  faPlus,
  faSearch,
  faClock,
  faCheckCircle,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons'
import EmployeeModal from './EmployeeModal'
import WarningModal from './WarningModal'
import EditEmployeeModal from './EditEmployeeModal'

const formatDate = (dateString) => {
  const date = new Date(dateString)
  const year = date.getFullYear().toString().slice(-2)
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')
  return `${year}-${month}-${day}`
}

const EmployeeList = () => {
  const dispatch = useDispatch()
  const { employees, metadata, loading } = useSelector(
    (state) => state.employees
  )

  const [currentPage, setCurrentPage] = useState(1)
  const pageSize = 10

  useEffect(() => {
    dispatch(getAllEmployees({ page: currentPage, size: pageSize }))
  }, [dispatch, currentPage])

  const [menuOpen, setMenuOpen] = useState(null)
  const menuRef = useRef(null)
  const deleteButtonRef = useRef(null)
  const editButtonRef = useRef(null)
  const [modalOpen, setModalOpen] = useState(false)
  const [warningModalOpen, setWarningModalOpen] = useState(false)
  const [employeeToDelete, setEmployeeToDelete] = useState(null)
  const [searchQuery, setSearchQuery] = useState('')
  const [editModalOpen, setEditModalOpen] = useState(false)
  const [employeeToEdit, setEmployeeToEdit] = useState(null)

  const toggleMenu = (id) => {
    setMenuOpen(menuOpen === id ? null : id)
  }

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber)
  }

  const handleEdit = (employee) => {
    setEmployeeToEdit(employee)
    setEditModalOpen(true)
    setMenuOpen(null)
  }

  const handleDelete = (id) => {
    console.log(`Delete button clicked for employee ID: ${id}`)
    setEmployeeToDelete(id)
    setWarningModalOpen(true)
  }

  const confirmDelete = () => {
    if (employeeToDelete) {
      dispatch(deleteEmployee(employeeToDelete))
      setEmployeeToDelete(null)
      setWarningModalOpen(false)
    }
  }

  const cancelDelete = () => {
    setEmployeeToDelete(null)
    setWarningModalOpen(false)
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        deleteButtonRef.current &&
        !deleteButtonRef.current.contains(event.target) &&
        editButtonRef.current &&
        !editButtonRef.current.contains(event.target)
      ) {
        setMenuOpen(null)
        setEditModalOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const getStatusStyles = (status) => {
    switch (status) {
      case 'working':
        return { color: 'text-green-600', icon: faCheckCircle }
      case 'probation':
        return { color: 'text-orange-600', icon: faClock }
      case 'left':
        return { color: 'text-red-600', icon: faTimesCircle }
      default:
        return { color: 'text-gray-600', icon: faClock }
    }
  }

  if (loading) return <p className="text-center text-lg">Loading...</p>

  return (
    <div className="container mx-auto p-4">
      <div className="mb-4 flex items-center justify-between">
        <button
          onClick={() => setModalOpen(true)}
          className="btn flex items-center rounded bg-brandPrimary px-4 py-2 text-white"
        >
          <FontAwesomeIcon icon={faPlus} className="mr-2" /> Add Employee
        </button>
        <div className="flex items-center space-x-2">
          <input
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="rounded-lg border border-gray-300 px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <button className="btn flex items-center rounded bg-gray-200 px-4 py-2 text-gray-600 hover:bg-gray-300">
            <FontAwesomeIcon icon={faSearch} className="mr-2" /> Search
          </button>
        </div>
      </div>
      <div className="overflow-x-auto">
        <table className="min-w-full rounded-lg border border-gray-200 bg-white shadow-md">
          <thead className="bg-gray-100 text-left text-gray-600">
            <tr>
              <th className="px-4 py-2">ID</th>
              <th className="px-4 py-2">First Name</th>
              <th className="px-4 py-2">Last Name</th>
              <th className="px-4 py-2">Role</th>
              <th className="px-4 py-2">Email</th>
              <th className="px-4 py-2">Phone Number</th>
              <th className="px-4 py-2">Department</th>
              <th className="px-4 py-2">Date of Joining</th>
              <th className="px-4 py-2">Employee Status</th>
              <th className="px-4 py-2">Actions</th>
            </tr>
          </thead>
          <tbody>
            {employees
              .filter(
                (employee) =>
                  employee.email &&
                  employee.role &&
                  employee.employeeStatus &&
                  employee.lastName &&
                  employee.department &&
                  employee.firstName
                    .toLowerCase()
                    .includes(searchQuery.toLowerCase())
              )
              .map((employee) => {
                const statusStyles = getStatusStyles(employee.employeeStatus)
                return (
                  <tr
                    key={employee._id}
                    className="border-b border-gray-200 hover:bg-gray-50"
                  >
                    <td className="px-4 py-2">
                      {employee._id.substring(0, 6)}
                    </td>
                    <td className="px-4 py-2">{employee.firstName}</td>
                    <td className="px-4 py-2">{employee.lastName}</td>
                    <td className="px-4 py-2">{employee.role}</td>
                    <td className="px-4 py-2">{employee.email}</td>
                    <td className="px-4 py-2">{employee.phoneNumber}</td>
                    <td className="px-4 py-2">{employee.department}</td>
                    <td className="px-4 py-2">
                      {formatDate(employee.dateOfJoining)}
                    </td>
                    <td className={`px-4 py-2 ${statusStyles.color}`}>
                      <FontAwesomeIcon
                        icon={statusStyles.icon}
                        className="mr-2"
                      />
                      {employee.employeeStatus}
                    </td>
                    <td className="px-4 py-2">
                      <div className="relative flex items-center" ref={menuRef}>
                        <button
                          onClick={() => toggleMenu(employee._id)}
                          className="text-gray-600 hover:text-gray-900 focus:outline-none"
                        >
                          <FontAwesomeIcon icon={faEllipsisV} />
                        </button>
                        {menuOpen === employee._id && (
                          <div className="absolute right-0 z-10 mt-1 flex items-center space-x-2">
                            <button
                              ref={editButtonRef} // Add ref here
                              onClick={() => handleEdit(employee)}
                              className="block flex items-center py-2 text-gray-700 hover:bg-gray-100"
                            >
                              <FontAwesomeIcon icon={faEdit} className="ml-1" />
                            </button>
                            <button
                              ref={deleteButtonRef} // Add ref here
                              onClick={() => handleDelete(employee._id)}
                              className="block flex items-center py-2 text-gray-700 hover:bg-gray-100"
                            >
                              <FontAwesomeIcon
                                icon={faTrash}
                                className="ml-1 text-red-600"
                              />
                            </button>
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                )
              })}
          </tbody>
        </table>
      </div>
      <div className="mt-4 flex items-center justify-between">
        <button
          onClick={() => handlePageChange(metadata.current_page - 1)}
          disabled={!metadata.has_previous_page}
          className="btn rounded bg-gray-200 px-4 py-2 text-gray-600 hover:bg-gray-300"
        >
          Previous
        </button>
        <span className="text-gray-700">
          Page {metadata.current_page} of {metadata.last_page}
        </span>
        <button
          onClick={() => handlePageChange(metadata.current_page + 1)}
          disabled={!metadata.has_next_page}
          className="btn rounded bg-gray-200 px-4 py-2 text-gray-600 hover:bg-gray-300"
        >
          Next
        </button>
      </div>
      <EmployeeModal isOpen={modalOpen} onClose={() => setModalOpen(false)} />

      <EditEmployeeModal
        isOpen={editModalOpen}
        onClose={() => setEditModalOpen(false)}
        employee={employeeToEdit}
      />

      <WarningModal
        isOpen={warningModalOpen}
        onClose={cancelDelete}
        employeeName={
          employeeToDelete
            ? employees.find((e) => e._id === employeeToDelete)?.firstName
            : ''
        }
        onConfirm={confirmDelete}
      />
    </div>
  )
}

export default EmployeeList
