import React from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faTachometerAlt,
  faUser,
  faBox,
  faListAlt,
  faWarehouse,
  faFileInvoice,
  faClinicMedical,
  faUsers,
  faBoxOpen,
  faTag,
  faQuoteLeft,
} from '@fortawesome/free-solid-svg-icons'

function Sidebar() {
  return (
    <div className="flex w-64 flex-col bg-gray-100 text-gray-900">
      <div className="flex items-center justify-center p-4">
        <img
          src="https://healthcamp-products.s3.us-east-2.amazonaws.com/Frame+6322.svg"
          alt="Logo"
          className="h-12 w-auto"
        />
      </div>
      <nav className="mt-10 flex-1">
        <ul>
          <li className="mb-2">
            <Link
              to="home"
              className="mx-auto flex w-40 items-center justify-center rounded-md bg-brandPrimary p-2 text-white transition-colors duration-300 hover:bg-brandSecondary"
            >
              <FontAwesomeIcon icon={faTachometerAlt} className="mr-2" />
              Dashboard
            </Link>
          </li>
          <li className="mb-2">
            <Link
              to="admin"
              className="mx-auto flex w-40 items-center justify-center rounded-md bg-brandPrimary p-2 text-white transition-colors duration-300 hover:bg-brandSecondary"
            >
              <FontAwesomeIcon icon={faUser} className="mr-2" />
              Admin
            </Link>
          </li>
          <li className="mb-2">
            <Link
              to="suppliers"
              className="mx-auto flex w-40 items-center justify-center rounded-md bg-brandPrimary p-2 text-white transition-colors duration-300 hover:bg-brandSecondary"
            >
              <FontAwesomeIcon icon={faBox} className="mr-2" />
              Suppliers
            </Link>
          </li>
          <li className="mb-2">
            <Link
              to="orders"
              className="mx-auto flex w-40 items-center justify-center rounded-md bg-brandPrimary p-2 text-white transition-colors duration-300 hover:bg-brandSecondary"
            >
              <FontAwesomeIcon icon={faListAlt} className="mr-2" />
              Orders
            </Link>
          </li>
          <li className="mb-2">
            <Link
              to="inventory"
              className="mx-auto flex w-40 items-center justify-center rounded-md bg-brandPrimary p-2 text-white transition-colors duration-300 hover:bg-brandSecondary"
            >
              <FontAwesomeIcon icon={faWarehouse} className="mr-2" />
              Inventory
            </Link>
          </li>
          <li className="mb-2">
            <Link
              to="rfq"
              className="mx-auto flex w-40 items-center justify-center rounded-md bg-brandPrimary p-2 text-white transition-colors duration-300 hover:bg-brandSecondary"
            >
              <FontAwesomeIcon icon={faFileInvoice} className="mr-2" />
              RFQ
            </Link>
          </li>
          <li className="mb-2">
            <Link
              to="quotations"
              className="mx-auto flex w-40 items-center justify-center rounded-md bg-brandPrimary p-2 text-white transition-colors duration-300 hover:bg-brandSecondary"
            >
              <FontAwesomeIcon icon={faQuoteLeft} className="mr-2" />
              Quotations
            </Link>
          </li>
          <li className="mb-2">
            <Link
              to="healthcamps"
              className="mx-auto flex w-40 items-center justify-center rounded-md bg-brandPrimary p-2 text-white transition-colors duration-300 hover:bg-brandSecondary"
            >
              <FontAwesomeIcon icon={faClinicMedical} className="mr-2" />
              Healthcamps
            </Link>
          </li>
          <li className="mb-2">
            <Link
              to="employees"
              className="mx-auto flex w-40 items-center justify-center rounded-md bg-brandPrimary p-2 text-white transition-colors duration-300 hover:bg-brandSecondary"
            >
              <FontAwesomeIcon icon={faUsers} className="mr-2" />
              Employees
            </Link>
          </li>
          <li className="mb-2">
            <Link
              to="issuing-items"
              className="mx-auto flex w-40 items-center justify-center rounded-md bg-brandPrimary p-2 text-white transition-colors duration-300 hover:bg-brandSecondary"
            >
              <FontAwesomeIcon icon={faBoxOpen} className="mr-2" />
              Issuing Items
            </Link>
          </li>
          {/* <li className="mb-2">
            <Link to="zuri-assets" className="w-40 mx-auto p-2 bg-brandPrimary text-white hover:bg-brandSecondary rounded-md flex items-center justify-center transition-colors duration-300">
              <FontAwesomeIcon icon={faTag} className="mr-2" />
              Zuri Assets
            </Link>
          </li> */}
        </ul>
      </nav>
    </div>
  )
}

export default Sidebar
