import {
  ADD_RFQ_REQUEST,
  ADD_RFQ_SUCCESS,
  ADD_RFQ_FAILURE,
  EDIT_RFQ_REQUEST,
  EDIT_RFQ_SUCCESS,
  EDIT_RFQ_FAILURE,
  DELETE_RFQ_REQUEST,
  DELETE_RFQ_SUCCESS,
  DELETE_RFQ_FAILURE,
  TRACK_RFQS_REQUEST,
  TRACK_RFQS_SUCCESS,
  TRACK_RFQS_FAILURE,
  ADD_QUOTATION_REQUEST,
  ADD_QUOTATION_SUCCESS,
  ADD_QUOTATION_FAILURE,
  CREATE_QUOTATION_REQUEST,
  CREATE_QUOTATION_SUCCESS,
  CREATE_QUOTATION_FAILURE,
  UPDATE_QUOTATION_REQUEST,
  UPDATE_QUOTATION_SUCCESS,
  UPDATE_QUOTATION_FAILURE,
  DELETE_QUOTATION_REQUEST,
  DELETE_QUOTATION_SUCCESS,
  DELETE_QUOTATION_FAILURE,
  GET_RFQ_QUOTATIONS_FAILURE,
  GET_RFQ_QUOTATIONS_REQUEST,
  GET_RFQ_QUOTATIONS_SUCCESS,
} from '../constants/rfqConstants'

const initialState = {
  rfqs: [],
  quotations: [],
  metadata: {},
  loading: false,
  errors: {},
}

export const rfqReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_RFQ_REQUEST:
    case EDIT_RFQ_REQUEST:
    case DELETE_RFQ_REQUEST:
    case TRACK_RFQS_REQUEST:
    case ADD_QUOTATION_REQUEST:
    case CREATE_QUOTATION_REQUEST:
    case UPDATE_QUOTATION_REQUEST:
    case DELETE_QUOTATION_REQUEST:
    case GET_RFQ_QUOTATIONS_REQUEST:
      return { ...state, loading: true }

    case ADD_RFQ_SUCCESS:
      return {
        ...state,
        rfqs: [...state.rfqs, action.payload.rfq],
        loading: false,
      }

    case EDIT_RFQ_SUCCESS:
      return {
        ...state,
        rfqs: state.rfqs.map((rfq) =>
          rfq._id === action.payload.rfq._id ? action.payload.rfq : rfq
        ),
        loading: false,
      }

    case DELETE_RFQ_SUCCESS:
      return {
        ...state,
        loading: false,
        rfqs: state.rfqs.filter((rfq) => rfq._id !== action.payload),
      }

    case TRACK_RFQS_SUCCESS:
      return {
        ...state,
        rfqs: action.payload.rfqs,
        metadata: action.payload.metadata,
        loading: false,
      }

    case ADD_QUOTATION_SUCCESS:
    case CREATE_QUOTATION_SUCCESS:
      return {
        ...state,
        quotations: [...state.quotations, action.payload.quotation],
        loading: false,
      }

    case UPDATE_QUOTATION_SUCCESS:
      return {
        ...state,
        loading: false,
        quotations: state.quotations.map((quotation) =>
          quotation._id === action.payload.quotation._id
            ? action.payload.quotation
            : quotation
        ),
      }

    case DELETE_QUOTATION_SUCCESS:
      return {
        ...state,
        loading: false,
        quotations: state.quotations.filter(
          (quotation) => quotation._id !== action.payload
        ),
      }

    case GET_RFQ_QUOTATIONS_SUCCESS:
      return {
        ...state,
        quotations: action.payload.quotations,
        metadata: action.payload.metadata,
        loading: false,
      }

    case ADD_RFQ_FAILURE:
    case EDIT_RFQ_FAILURE:
    case DELETE_RFQ_FAILURE:
    case TRACK_RFQS_FAILURE:
    case ADD_QUOTATION_FAILURE:
    case CREATE_QUOTATION_FAILURE:
    case UPDATE_QUOTATION_FAILURE:
    case DELETE_QUOTATION_FAILURE:
    case GET_RFQ_QUOTATIONS_FAILURE:
      return { ...state, loading: false, errors: action.payload }

    default:
      return state
  }
}
