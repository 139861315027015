import {
  ADD_ISSUED_ITEM_REQUEST,
  ADD_ISSUED_ITEM_SUCCESS,
  ADD_ISSUED_ITEM_FAILURE,
  UPDATE_ITEM_STATUS_REQUEST,
  UPDATE_ITEM_STATUS_SUCCESS,
  UPDATE_ITEM_STATUS_FAILURE,
  GET_ITEMS_REQUEST,
  GET_ITEMS_SUCCESS,
  GET_ITEMS_FAILURE,
  DELETE_ITEM_REQUEST,
  DELETE_ITEM_SUCCESS,
  DELETE_ITEM_FAILURE,
} from '../constants/issuedItemConstants'
import { toast } from 'react-toastify'

const initialState = {
  items: [],
  metadata: {},
  loading: false,
  errors: {},
}

export default function issuedItemReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_ISSUED_ITEM_REQUEST:
    case UPDATE_ITEM_STATUS_REQUEST:
    case GET_ITEMS_REQUEST:
    case DELETE_ITEM_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case ADD_ISSUED_ITEM_SUCCESS:
      toast.success('Item added successfully')
      return {
        ...state,
        items: [...state.items, action.payload],
        loading: false,
      }
    case UPDATE_ITEM_STATUS_SUCCESS:
      toast.success('Item status updated successfully')
      return {
        ...state,
        items: state.items.map((item) =>
          item._id === action.payload._id ? action.payload : item
        ),
        loading: false,
      }
    case GET_ITEMS_SUCCESS:
      return {
        ...state,
        items: action.payload.data,
        metadata: action.payload.metadata,
        loading: false,
      }
    case DELETE_ITEM_SUCCESS:
      toast.success('Item deleted successfully')
      return {
        ...state,
        items: state.items.filter((item) => item._id !== action.payload),
        loading: false,
      }
    case ADD_ISSUED_ITEM_FAILURE:
      toast.error(`Failed to add item: ${action.payload.message}`)
      return {
        ...state,
        errors: action.payload,
        loading: false,
      }
    case UPDATE_ITEM_STATUS_FAILURE:
      toast.error(`Failed to update item status: ${action.payload.message}`)
      return {
        ...state,
        errors: action.payload,
        loading: false,
      }
    case GET_ITEMS_FAILURE:
      toast.error(`Failed to fetch items: ${action.payload.message}`)
      return {
        ...state,
        errors: action.payload,
        loading: false,
      }
    case DELETE_ITEM_FAILURE:
      toast.error(`Failed to delete item: ${action.payload.message}`)
      return {
        ...state,
        errors: action.payload,
        loading: false,
      }
    default:
      return state
  }
}
