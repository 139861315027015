import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { updateHealthCamp } from '../../actions/healthCampActions'

const EditHealthCampModal = ({ isOpen, onClose, healthCamp }) => {
  const dispatch = useDispatch()

  const [name, setName] = useState('')
  const [location, setLocation] = useState('')
  const [date, setDate] = useState('')
  const [items, setItems] = useState([])
  const [notes, setNotes] = useState('')

  useEffect(() => {
    if (healthCamp) {
      setName(healthCamp.name)
      setLocation(healthCamp.location)
      // Convert the date from ISO format to 'yyyy-MM-ddThh:mm'
      const formattedDate = new Date(healthCamp.date).toISOString().slice(0, 16)
      setDate(formattedDate)
      setItems(healthCamp.items)
      setNotes(healthCamp.notes)
    }
  }, [healthCamp])

  const handleItemChange = (index, field, value) => {
    const updatedItems = [...items]
    updatedItems[index] = { ...updatedItems[index], [field]: value }
    setItems(updatedItems)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    // Convert the date to ISO format for backend storage
    const updatedHealthCamp = {
      name,
      location,
      date: new Date(date).toISOString(),
      items,
      notes,
    }
    try {
      await dispatch(updateHealthCamp(healthCamp._id, updatedHealthCamp))
      toast.success('Health camp updated successfully')
      onClose()
    } catch (error) {
      toast.error('Failed to update health camp')
    }
  }

  if (!isOpen) return null

  return (
    <>
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
        <div className="relative w-full max-w-2xl rounded-lg bg-white p-6 shadow-lg">
          <button
            onClick={onClose}
            className="absolute right-4 top-4 text-gray-500 hover:text-gray-700 focus:outline-none"
          >
            <FontAwesomeIcon icon={faTimes} size="lg" />
          </button>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-700"
              >
                Name:
              </label>
              <input
                id="name"
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
            <div>
              <label
                htmlFor="location"
                className="block text-sm font-medium text-gray-700"
              >
                Location:
              </label>
              <input
                id="location"
                type="text"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
                required
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
            <div>
              <label
                htmlFor="date"
                className="block text-sm font-medium text-gray-700"
              >
                Date:
              </label>
              <input
                id="date"
                type="datetime-local"
                value={date}
                onChange={(e) => setDate(e.target.value)}
                required
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Items:
              </label>
              {items.map((item, index) => (
                <div
                  key={index}
                  className="mb-4 rounded-md border border-gray-300 p-4"
                >
                  <div>
                    <label
                      htmlFor={`itemName-${index}`}
                      className="block text-sm font-medium text-gray-700"
                    >
                      Item Name:
                    </label>
                    <input
                      id={`itemName-${index}`}
                      type="text"
                      value={item.itemName}
                      onChange={(e) =>
                        handleItemChange(index, 'itemName', e.target.value)
                      }
                      required
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor={`quantity-${index}`}
                      className="block text-sm font-medium text-gray-700"
                    >
                      Quantity:
                    </label>
                    <input
                      id={`quantity-${index}`}
                      type="number"
                      value={item.quantity}
                      onChange={(e) =>
                        handleItemChange(index, 'quantity', e.target.value)
                      }
                      required
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor={`unit-${index}`}
                      className="block text-sm font-medium text-gray-700"
                    >
                      Unit:
                    </label>
                    <input
                      id={`unit-${index}`}
                      type="text"
                      value={item.unit}
                      onChange={(e) =>
                        handleItemChange(index, 'unit', e.target.value)
                      }
                      required
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor={`specifications-${index}`}
                      className="block text-sm font-medium text-gray-700"
                    >
                      Specifications:
                    </label>
                    <input
                      id={`specifications-${index}`}
                      type="text"
                      value={item.specifications}
                      onChange={(e) =>
                        handleItemChange(
                          index,
                          'specifications',
                          e.target.value
                        )
                      }
                      required
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                    />
                  </div>
                </div>
              ))}
              <button
                type="button"
                onClick={() =>
                  setItems([
                    ...items,
                    {
                      itemName: '',
                      quantity: '',
                      unit: '',
                      specifications: '',
                    },
                  ])
                }
                className="text-blue-500 hover:text-blue-700 focus:outline-none"
              >
                Add Item
              </button>
            </div>
            <div>
              <label
                htmlFor="notes"
                className="block text-sm font-medium text-gray-700"
              >
                Notes:
              </label>
              <textarea
                id="notes"
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
            <button
              type="submit"
              className="w-full rounded-md bg-brandSecondary px-4 py-2 text-white hover:bg-brandPrimary focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
            >
              Update Health Camp
            </button>
          </form>
        </div>
      </div>
      <ToastContainer />
    </>
  )
}

export default EditHealthCampModal
