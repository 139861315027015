import React from 'react'
import RFQList from '../RFQ/RFQList'

function RFQPage() {
  return (
    <div>
      <RFQList />
    </div>
  )
}

export default RFQPage
