import { toast } from 'react-toastify'
import {
  ADD_HEALTH_CAMP_FAILURE,
  ADD_HEALTH_CAMP_REQUEST,
  ADD_HEALTH_CAMP_SUCCESS,
  DELETE_HEALTH_CAMP_FAILURE,
  DELETE_HEALTH_CAMP_REQUEST,
  DELETE_HEALTH_CAMP_SUCCESS,
  GET_ALL_HEALTH_CAMPS_FAILURE,
  GET_ALL_HEALTH_CAMPS_REQUEST,
  GET_ALL_HEALTH_CAMPS_SUCCESS,
  UPDATE_HEALTH_CAMP_FAILURE,
  UPDATE_HEALTH_CAMP_REQUEST,
  UPDATE_HEALTH_CAMP_SUCCESS,
} from '../constants/healthCampConstants'

const initialState = {
  healthCamps: [],
  metadata: {},
  loading: false,
  errors: {},
}

export default function healthCampReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_HEALTH_CAMP_REQUEST:
    case UPDATE_HEALTH_CAMP_REQUEST:
    case GET_ALL_HEALTH_CAMPS_REQUEST:
    case DELETE_HEALTH_CAMP_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case ADD_HEALTH_CAMP_SUCCESS:
      toast.success('Health camp added successfully')
      return {
        ...state,
        healthCamps: [...state.healthCamps, action.payload],
        loading: false,
      }
    case UPDATE_HEALTH_CAMP_SUCCESS:
      toast.success('Health camp updated successfully')
      return {
        ...state,
        healthCamps: state.healthCamps.map((healthCamp) =>
          healthCamp._id === action.payload._id ? action.payload : healthCamp
        ),
        loading: false,
      }
    case DELETE_HEALTH_CAMP_SUCCESS:
      toast.success('Health camp deleted successfully')
      return {
        ...state,
        healthCamps: state.healthCamps.filter(
          (healthCamp) => healthCamp._id !== action.payload
        ),
        loading: false,
      }
    case GET_ALL_HEALTH_CAMPS_SUCCESS:
      return {
        ...state,
        healthCamps: action.payload.healthCamps,
        metadata: action.payload.metadata,
        loading: false,
      }
    case ADD_HEALTH_CAMP_FAILURE:
      toast.error(`Failed to add health camp: ${action.payload.message}`)
      return {
        ...state,
        errors: action.payload,
        loading: false,
      }
    case UPDATE_HEALTH_CAMP_FAILURE:
      toast.error(`Failed to update health camp: ${action.payload.message}`)
      return {
        ...state,
        errors: action.payload,
        loading: false,
      }
    case DELETE_HEALTH_CAMP_FAILURE:
      toast.error(`Failed to delete health camp: ${action.payload.message}`)
      return {
        ...state,
        errors: action.payload,
        loading: false,
      }
    case GET_ALL_HEALTH_CAMPS_FAILURE:
      toast.error(`Failed to fetch health camps: ${action.payload.message}`)
      return {
        ...state,
        errors: action.payload,
        loading: false,
      }
    default:
      return state
  }
}
