// constants/issuedItemConstants.js
export const ADD_ISSUED_ITEM_REQUEST = 'ADD_ISSUED_ITEM_REQUEST'
export const ADD_ISSUED_ITEM_SUCCESS = 'ADD_ISSUED_ITEM_SUCCESS'
export const ADD_ISSUED_ITEM_FAILURE = 'ADD_ISSUED_ITEM_FAILURE'

export const UPDATE_ITEM_STATUS_REQUEST = 'UPDATE_ITEM_STATUS_REQUEST'
export const UPDATE_ITEM_STATUS_SUCCESS = 'UPDATE_ITEM_STATUS_SUCCESS'
export const UPDATE_ITEM_STATUS_FAILURE = 'UPDATE_ITEM_STATUS_FAILURE'

export const GET_ITEMS_REQUEST = 'GET_ITEMS_REQUEST'
export const GET_ITEMS_SUCCESS = 'GET_ITEMS_SUCCESS'
export const GET_ITEMS_FAILURE = 'GET_ITEMS_FAILURE'

export const DELETE_ITEM_REQUEST = 'DELETE_ITEM_REQUEST'
export const DELETE_ITEM_SUCCESS = 'DELETE_ITEM_SUCCESS'
export const DELETE_ITEM_FAILURE = 'DELETE_ITEM_FAILURE'
