export const ADD_PURCHASE_ORDER_REQUEST = 'ADD_PURCHASE_ORDER_REQUEST'
export const ADD_PURCHASE_ORDER_SUCCESS = 'ADD_PURCHASE_ORDER_SUCCESS'
export const ADD_PURCHASE_ORDER_FAILURE = 'ADD_PURCHASE_ORDER_FAILURE'

export const EDIT_PURCHASE_ORDER_REQUEST = 'EDIT_PURCHASE_ORDER_REQUEST'
export const EDIT_PURCHASE_ORDER_SUCCESS = 'EDIT_PURCHASE_ORDER_SUCCESS'
export const EDIT_PURCHASE_ORDER_FAILURE = 'EDIT_PURCHASE_ORDER_FAILURE'

export const DELETE_PURCHASE_ORDER_REQUEST = 'DELETE_PURCHASE_ORDER_REQUEST'
export const DELETE_PURCHASE_ORDER_SUCCESS = 'DELETE_PURCHASE_ORDER_SUCCESS'
export const DELETE_PURCHASE_ORDER_FAILURE = 'DELETE_PURCHASE_ORDER_FAILURE'

export const GET_ALL_PURCHASE_ORDERS_REQUEST = 'GET_ALL_PURCHASE_ORDERS_REQUEST'
export const GET_ALL_PURCHASE_ORDERS_SUCCESS = 'GET_ALL_PURCHASE_ORDERS_SUCCESS'
export const GET_ALL_PURCHASE_ORDERS_FAILURE = 'GET_ALL_PURCHASE_ORDERS_FAILURE'

export const SEARCH_PURCHASE_ORDER_REQUEST = 'SEARCH_PURCHASE_ORDER_REQUEST'
export const SEARCH_PURCHASE_ORDER_SUCCESS = 'SEARCH_PURCHASE_ORDER_SUCCESS'
export const SEARCH_PURCHASE_ORDER_FAILURE = 'SEARCH_PURCHASE_ORDER_FAILURE'
