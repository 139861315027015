// actions/employeeActions.js
import axios from 'axios'
import {
  ADD_EMPLOYEE_REQUEST,
  ADD_EMPLOYEE_SUCCESS,
  ADD_EMPLOYEE_FAILURE,
  UPDATE_EMPLOYEE_REQUEST,
  UPDATE_EMPLOYEE_SUCCESS,
  UPDATE_EMPLOYEE_FAILURE,
  DELETE_EMPLOYEE_REQUEST,
  DELETE_EMPLOYEE_SUCCESS,
  DELETE_EMPLOYEE_FAILURE,
  GET_ALL_EMPLOYEES_REQUEST,
  GET_ALL_EMPLOYEES_SUCCESS,
  GET_ALL_EMPLOYEES_FAILURE,
} from '../constants/employeeConstants'
import { API_URL, API_KEY } from '../config/config'
import { toast } from 'react-toastify'

const axiosConfig = {
  headers: {
    'X-API-KEY': API_KEY,
  },
}

// Add Employee
export const addEmployee = (employeeData) => async (dispatch) => {
  dispatch({ type: ADD_EMPLOYEE_REQUEST })
  try {
    const res = await axios.post(
      `${API_URL}/employees/new`,
      employeeData,
      axiosConfig
    )
    dispatch({
      type: ADD_EMPLOYEE_SUCCESS,
      payload: res.data,
    })
    toast.success('Employee added successfully')
  } catch (error) {
    dispatch({
      type: ADD_EMPLOYEE_FAILURE,
      payload: error.response.data,
    })
    toast.error('Failed to add employee')
  }
}

// Update Employee
export const updateEmployee =
  (employeeId, employeeData) => async (dispatch) => {
    dispatch({ type: UPDATE_EMPLOYEE_REQUEST })
    try {
      const res = await axios.put(
        `${API_URL}/employees/employee/${employeeId}`,
        employeeData,
        axiosConfig
      )
      dispatch({
        type: UPDATE_EMPLOYEE_SUCCESS,
        payload: res.data,
      })
      toast.success('Employee updated successfully')
    } catch (error) {
      dispatch({
        type: UPDATE_EMPLOYEE_FAILURE,
        payload: error.response.data,
      })
      toast.error('Failed to update employee')
    }
  }

// Delete Employee
export const deleteEmployee = (employeeId) => async (dispatch) => {
  dispatch({ type: DELETE_EMPLOYEE_REQUEST })
  try {
    await axios.delete(
      `${API_URL}/employees/employee/${employeeId}`,
      axiosConfig
    )
    dispatch({
      type: DELETE_EMPLOYEE_SUCCESS,
      payload: employeeId,
    })
    toast.success('Employee deleted successfully')
  } catch (error) {
    dispatch({
      type: DELETE_EMPLOYEE_FAILURE,
      payload: error.response.data,
    })
    toast.error('Failed to delete employee')
  }
}

// Get All Employees
export const getAllEmployees =
  ({ page = 1, size = 10 } = {}) =>
  async (dispatch) => {
    dispatch({ type: GET_ALL_EMPLOYEES_REQUEST })
    try {
      const { data } = await axios.get(`${API_URL}/employees/all`, {
        params: { page, size },
        ...axiosConfig,
      })
      dispatch({
        type: GET_ALL_EMPLOYEES_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: GET_ALL_EMPLOYEES_FAILURE,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }
