export const ADD_INVENTORY_REQUEST = 'ADD_INVENTORY_REQUEST'
export const ADD_INVENTORY_SUCCESS = 'ADD_INVENTORY_SUCCESS'
export const ADD_INVENTORY_FAILURE = 'ADD_INVENTORY_FAILURE'

export const EDIT_INVENTORY_REQUEST = 'EDIT_INVENTORY_REQUEST'
export const EDIT_INVENTORY_SUCCESS = 'EDIT_INVENTORY_SUCCESS'
export const EDIT_INVENTORY_FAILURE = 'EDIT_INVENTORY_FAILURE'

export const DELETE_INVENTORY_REQUEST = 'DELETE_INVENTORY_REQUEST'
export const DELETE_INVENTORY_SUCCESS = 'DELETE_INVENTORY_SUCCESS'
export const DELETE_INVENTORY_FAILURE = 'DELETE_INVENTORY_FAILURE'

export const GET_ALL_INVENTORY_REQUEST = 'GET_ALL_INVENTORY_REQUEST'
export const GET_ALL_INVENTORY_SUCCESS = 'GET_ALL_INVENTORY_SUCCESS'
export const GET_ALL_INVENTORY_FAILURE = 'GET_ALL_INVENTORY_FAILURE'

export const SEARCH_INVENTORY_REQUEST = 'SEARCH_INVENTORY_REQUEST'
export const SEARCH_INVENTORY_SUCCESS = 'SEARCH_INVENTORY_SUCCESS'
export const SEARCH_INVENTORY_FAILURE = 'SEARCH_INVENTORY_FAILURE'
