import React from 'react'
import OrderList from '../PurchaseOrders/OrderList'

function OrdersPage() {
  return (
    <div>
      <OrderList />
    </div>
  )
}

export default OrdersPage
