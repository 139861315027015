import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { addRFQ } from '../../actions/rfqActions' // Import your RFQ action

const RFQModal = ({ isOpen, onClose }) => {
  const dispatch = useDispatch()

  const [item, setItem] = useState('')
  const [quantity, setQuantity] = useState('')
  const [unit, setUnit] = useState('')
  const [specifications, setSpecifications] = useState('')
  const [requestDate, setRequestDate] = useState('')

  const commonUnits = ['pcs', 'kg', 'liters', 'meters', 'boxes', 'units'] // Define your common units here

  const handleSubmit = async (e) => {
    e.preventDefault()
    const rfqData = { item, quantity, unit, specifications, requestDate }
    try {
      await dispatch(addRFQ(rfqData)) // Dispatch the action to add RFQ
      toast.success('RFQ added successfully')
      onClose()
    } catch (error) {
      toast.error('Failed to add RFQ')
    }
  }

  if (!isOpen) return null

  return (
    <>
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
        <div className="relative w-full max-w-md rounded-lg bg-white p-6 shadow-lg">
          <button
            onClick={onClose}
            className="absolute right-4 top-4 text-gray-500 hover:text-gray-700 focus:outline-none"
          >
            <FontAwesomeIcon icon={faTimes} size="lg" />
          </button>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label
                htmlFor="item"
                className="block text-sm font-medium text-gray-700"
              >
                Item:
              </label>
              <input
                id="item"
                type="text"
                value={item}
                onChange={(e) => setItem(e.target.value)}
                required
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
            <div>
              <label
                htmlFor="quantity"
                className="block text-sm font-medium text-gray-700"
              >
                Quantity:
              </label>
              <input
                id="quantity"
                type="number"
                value={quantity}
                onChange={(e) => setQuantity(e.target.value)}
                required
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
            <div>
              <label
                htmlFor="unit"
                className="block text-sm font-medium text-gray-700"
              >
                Unit:
              </label>
              <select
                id="unit"
                value={unit}
                onChange={(e) => setUnit(e.target.value)}
                required
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              >
                <option value="" disabled>
                  Select unit
                </option>
                {commonUnits.map((unitOption) => (
                  <option key={unitOption} value={unitOption}>
                    {unitOption}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label
                htmlFor="specifications"
                className="block text-sm font-medium text-gray-700"
              >
                Specifications:
              </label>
              <input
                id="specifications"
                type="text"
                value={specifications}
                onChange={(e) => setSpecifications(e.target.value)}
                required
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
            <div>
              <label
                htmlFor="requestDate"
                className="block text-sm font-medium text-gray-700"
              >
                Request Date:
              </label>
              <input
                id="requestDate"
                type="date"
                value={requestDate}
                onChange={(e) => setRequestDate(e.target.value)}
                required
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
            <button
              type="submit"
              className="w-full rounded-md bg-brandSecondary px-4 py-2 text-white hover:bg-brandPrimary focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
            >
              Add RFQ
            </button>
          </form>
        </div>
      </div>
      <ToastContainer />
    </>
  )
}

export default RFQModal
