import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { createQuotation } from '../../actions/rfqActions' // Import your Quotation action

const AddQuotationModal = ({ isOpen, onClose }) => {
  const dispatch = useDispatch()

  const [supplierPhoneNumber, setSupplierPhoneNumber] = useState('') // Updated state name
  const [price, setPrice] = useState('')
  const [validUntil, setValidUntil] = useState('')

  const handleSubmit = async (e) => {
    e.preventDefault()
    const quotationData = { supplierPhoneNumber, price, validUntil } // Updated field name
    try {
      await dispatch(createQuotation(quotationData)) // Dispatch the action to add Quotation
      toast.success('Quotation added successfully')
      onClose()
    } catch (error) {
      toast.error('Failed to add quotation')
    }
  }

  if (!isOpen) return null

  return (
    <>
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
        <div className="relative w-full max-w-md rounded-lg bg-white p-6 shadow-lg">
          <button
            onClick={onClose}
            className="absolute right-4 top-4 text-gray-500 hover:text-gray-700 focus:outline-none"
          >
            <FontAwesomeIcon icon={faTimes} size="lg" />
          </button>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label
                htmlFor="supplierPhoneNumber"
                className="block text-sm font-medium text-gray-700"
              >
                Supplier Phone Number:
              </label>
              <input
                id="supplierPhoneNumber"
                type="text"
                value={supplierPhoneNumber}
                onChange={(e) => setSupplierPhoneNumber(e.target.value)}
                required
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
            <div>
              <label
                htmlFor="price"
                className="block text-sm font-medium text-gray-700"
              >
                Price:
              </label>
              <input
                id="price"
                type="text"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                required
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
            <div>
              <label
                htmlFor="validUntil"
                className="block text-sm font-medium text-gray-700"
              >
                Valid Until:
              </label>
              <input
                id="validUntil"
                type="date"
                value={validUntil}
                onChange={(e) => setValidUntil(e.target.value)}
                required
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
            <button
              type="submit"
              className="w-full rounded-md bg-brandSecondary px-4 py-2 text-white hover:bg-brandPrimary focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
            >
              Add Quotation
            </button>
          </form>
        </div>
      </div>
      <ToastContainer />
    </>
  )
}

export default AddQuotationModal
