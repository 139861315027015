import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { register } from '../../actions/userActions' // Assuming you have a register action
import { useNavigate } from 'react-router-dom'
import { FaEye, FaEyeSlash } from 'react-icons/fa'
import { toast } from 'react-toastify'

function AdminRegistration() {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const auth = useSelector((state) => state.auth)
  const { loading, errors, user } = auth

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(register(firstName, lastName, email, password))
  }

  useEffect(() => {
    if (user) {
      navigate('/dashboard/home')
    }
    if (errors) {
      toast.error(errors)
    }
  }, [user, errors, navigate])

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev)
  }

  return (
    <div className="flex h-screen w-full flex-col items-center justify-center bg-gradient-to-b from-[#26CED1] to-[#EA516F] p-4">
      <div className="mb-4 w-full max-w-lg text-center">
        <p className="text-center font-bold text-red-600">
          Access to this website is strictly restricted to authorized personnel
          only. Unauthorized entry is prohibited and will be subject to severe
          legal consequences.
        </p>
      </div>
      <div className="w-full max-w-lg rounded-lg bg-white p-4 shadow-lg">
        <div className="mb-6 flex justify-center">
          <img
            src="https://healthcamp-products.s3.us-east-2.amazonaws.com/Frame+6322.svg"
            alt="Health Camp Logo"
            className="h-18 w-auto"
          />
        </div>
        <h2 className="mb-6 text-center text-2xl font-bold text-[#EA516F]">
          ZURI HRM
        </h2>
        <form onSubmit={submitHandler} className="space-y-6">
          <div>
            <label
              className="mb-2 block text-sm font-bold text-gray-700"
              htmlFor="firstName"
            >
              First Name
            </label>
            <input
              type="text"
              id="firstName"
              placeholder="Enter your first name"
              className="w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-[#EA516F] focus:outline-none focus:ring-[#EA516F]"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              required
            />
          </div>
          <div>
            <label
              className="mb-2 block text-sm font-bold text-gray-700"
              htmlFor="lastName"
            >
              Last Name
            </label>
            <input
              type="text"
              id="lastName"
              placeholder="Enter your last name"
              className="w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-[#EA516F] focus:outline-none focus:ring-[#EA516F]"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              required
            />
          </div>
          <div>
            <label
              className="mb-2 block text-sm font-bold text-gray-700"
              htmlFor="email"
            >
              Email
            </label>
            <input
              type="email"
              id="email"
              placeholder="Enter your email address"
              className="w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-[#EA516F] focus:outline-none focus:ring-[#EA516F]"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div>
            <label
              className="mb-2 block text-sm font-bold text-gray-700"
              htmlFor="password"
            >
              Password
            </label>
            <div className="relative">
              <input
                type={showPassword ? 'text' : 'password'}
                id="password"
                placeholder="Enter your password"
                className="w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-[#EA516F] focus:outline-none focus:ring-[#EA516F]"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <button
                type="button"
                onClick={togglePasswordVisibility}
                className="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-600"
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </button>
            </div>
          </div>
          <div className="flex items-center justify-between">
            <button
              type="submit"
              className="w-full rounded-md bg-[#EA516F] px-4 py-2 font-bold text-white hover:bg-[#d44661] focus:outline-none focus:ring-2 focus:ring-[#EA516F] focus:ring-opacity-50"
            >
              {loading ? 'Loading...' : 'Register'}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default AdminRegistration
