export const ADD_HEALTH_CAMP_REQUEST = 'ADD_HEALTH_CAMP_REQUEST'
export const ADD_HEALTH_CAMP_SUCCESS = 'ADD_HEALTH_CAMP_SUCCESS'
export const ADD_HEALTH_CAMP_FAILURE = 'ADD_HEALTH_CAMP_FAILURE'

export const UPDATE_HEALTH_CAMP_REQUEST = 'UPDATE_HEALTH_CAMP_REQUEST'
export const UPDATE_HEALTH_CAMP_SUCCESS = 'UPDATE_HEALTH_CAMP_SUCCESS'
export const UPDATE_HEALTH_CAMP_FAILURE = 'UPDATE_HEALTH_CAMP_FAILURE'

export const GET_ALL_HEALTH_CAMPS_REQUEST = 'GET_ALL_HEALTH_CAMPS_REQUEST'
export const GET_ALL_HEALTH_CAMPS_SUCCESS = 'GET_ALL_HEALTH_CAMPS_SUCCESS'
export const GET_ALL_HEALTH_CAMPS_FAILURE = 'GET_ALL_HEALTH_CAMPS_FAILURE'

export const DELETE_HEALTH_CAMP_REQUEST = 'DELETE_HEALTH_CAMP_REQUEST'
export const DELETE_HEALTH_CAMP_SUCCESS = 'DELETE_HEALTH_CAMP_SUCCESS'
export const DELETE_HEALTH_CAMP_FAILURE = 'DELETE_HEALTH_CAMP_FAILURE'
