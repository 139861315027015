import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteRFQ, trackRFQs } from '../../actions/rfqActions' // Make sure to implement these actions
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEllipsisV,
  faEdit,
  faTrash,
  faPlus,
  faSearch,
  faPlusCircle,
} from '@fortawesome/free-solid-svg-icons'
import RFQModal from './RFQModal'
import WarningRFQModal from './WarningRFQModal'
import EditRFQModal from './EditRFQModal'
import AddQuotationToRFQ from './AddQuotationToRFQ' // Import the new modal
import { toast } from 'react-toastify'

const RFQList = () => {
  const dispatch = useDispatch()
  const { rfqs, loading, metadata } = useSelector((state) => state.rfqs)

  const [currentPage, setCurrentPage] = useState(1)
  const pageSize = 10

  useEffect(() => {
    dispatch(trackRFQs({ page: currentPage, size: pageSize }))
  }, [dispatch, currentPage])

  const [menuOpen, setMenuOpen] = useState(null)
  const menuRef = useRef(null)
  const deleteButtonRef = useRef(null)
  const editButtonRef = useRef(null)
  const [modalOpen, setModalOpen] = useState(false)
  const [warningModalOpen, setWarningModalOpen] = useState(false)
  const [rfqToDelete, setRFQToDelete] = useState(null)
  const [searchQuery, setSearchQuery] = useState('')
  const [editModalOpen, setEditModalOpen] = useState(false)
  const [rfqToEdit, setRFQToEdit] = useState(null)

  const [addQuotationModalOpen, setAddQuotationModalOpen] = useState(false)
  const [selectedRFQ, setSelectedRFQ] = useState(null)

  const toggleMenu = (id) => {
    setMenuOpen(menuOpen === id ? null : id)
  }

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber)
  }

  const handleEdit = (rfq) => {
    setRFQToEdit(rfq)
    setEditModalOpen(true)
    setMenuOpen(null)
  }

  const handleDelete = (id) => {
    setRFQToDelete(id)
    setWarningModalOpen(true)
  }

  const confirmDelete = () => {
    if (rfqToDelete) {
      dispatch(deleteRFQ(rfqToDelete))
      setRFQToDelete(null)
      setWarningModalOpen(false)
    }
  }

  const cancelDelete = () => {
    setRFQToDelete(null)
    setWarningModalOpen(false)
  }

  const handleAddQuotation = (rfq) => {
    if (rfq && rfq._id) {
      // Ensure rfq._id is defined
      setSelectedRFQ(rfq)
      setAddQuotationModalOpen(true)
      setMenuOpen(null)
    } else {
      toast.error('RFQ ID is missing.')
    }
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        deleteButtonRef.current &&
        !deleteButtonRef.current.contains(event.target) &&
        editButtonRef.current &&
        !editButtonRef.current.contains(event.target)
      ) {
        setMenuOpen(null)
        setEditModalOpen(false)
        setAddQuotationModalOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  if (loading) return <p className="text-center text-lg">Loading...</p>

  return (
    <div className="container mx-auto p-4">
      <div className="mb-4 flex items-center justify-between">
        <button
          onClick={() => setModalOpen(true)}
          className="btn flex items-center rounded bg-brandPrimary px-4 py-2 text-white"
        >
          <FontAwesomeIcon icon={faPlus} className="mr-2" /> Add RFQ
        </button>
        <div className="flex items-center space-x-2">
          <input
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="rounded-lg border border-gray-300 px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <button className="btn flex items-center rounded bg-gray-200 px-4 py-2 text-gray-600 hover:bg-gray-300">
            <FontAwesomeIcon icon={faSearch} className="mr-2" /> Search
          </button>
        </div>
      </div>
      <div className="overflow-x-auto">
        <table className="min-w-full rounded-lg border border-gray-200 bg-white shadow-md">
          <thead className="bg-gray-100 text-left text-gray-600">
            <tr>
              <th className="px-4 py-2">ID</th>
              <th className="px-4 py-2">Item</th>
              <th className="px-4 py-2">Quantity</th>
              <th className="px-4 py-2">Unit</th>
              <th className="px-4 py-2">Specifications</th>
              <th className="px-4 py-2">Quotations</th>
              <th className="px-4 py-2">Actions</th>
            </tr>
          </thead>
          <tbody>
            {rfqs
              .filter(
                (rfqs) =>
                  rfqs.item &&
                  rfqs.item.toLowerCase().includes(searchQuery.toLowerCase())
              )
              .map((rfqs) => (
                <tr
                  key={rfqs._id}
                  className="border-b border-gray-200 hover:bg-gray-50"
                >
                  <td className="px-4 py-2">{rfqs._id.substring(0, 6)}</td>
                  <td className="px-4 py-2">{rfqs.item}</td>
                  <td className="px-4 py-2">{rfqs.quantity}</td>
                  <td className="px-4 py-2">{rfqs.unit}</td>
                  <td className="px-4 py-2">{rfqs.specifications}</td>
                  <td className="px-4 py-2">
                    {rfqs.quotations.map((quotation) => (
                      <div key={quotation._id}>
                        ${quotation.price} - {quotation.supplier.name}
                      </div>
                    ))}
                  </td>
                  <td className="px-4 py-2">
                    <div className="relative flex items-center" ref={menuRef}>
                      <button
                        onClick={() => toggleMenu(rfqs._id)}
                        className="text-gray-600 hover:text-gray-900 focus:outline-none"
                      >
                        <FontAwesomeIcon icon={faEllipsisV} />
                      </button>
                      {menuOpen === rfqs._id && (
                        <div className="absolute right-0 z-10 mt-1 flex items-center space-x-2">
                          <button
                            ref={editButtonRef}
                            onClick={() => handleEdit(rfqs)}
                            className="block flex items-center py-2 text-gray-700 hover:bg-gray-100"
                          >
                            <FontAwesomeIcon icon={faEdit} className="ml-1" />
                          </button>
                          <button
                            ref={deleteButtonRef}
                            onClick={() => handleDelete(rfqs._id)}
                            className="block flex items-center py-2 text-gray-700 hover:bg-gray-100"
                          >
                            <FontAwesomeIcon
                              icon={faTrash}
                              className="ml-1 text-red-600"
                            />
                          </button>
                          <button
                            onClick={() => handleAddQuotation(rfqs)}
                            className="block flex items-center py-2 text-gray-700 hover:bg-gray-100"
                          >
                            <FontAwesomeIcon
                              icon={faPlusCircle}
                              className="ml-1 text-green-600"
                            />
                            {/* <span className="ml-1">Add Quotation</span> */}
                          </button>
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <div className="mt-4">
        <nav className="flex justify-between">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className="btn rounded bg-gray-200 px-4 py-2 text-gray-600 hover:bg-gray-300"
          >
            Previous
          </button>
          <span className="text-gray-600">
            Page {currentPage} of {Math.ceil(metadata.total / pageSize)}
          </span>
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage * pageSize >= metadata.total}
            className="btn rounded bg-gray-200 px-4 py-2 text-gray-600 hover:bg-gray-300"
          >
            Next
          </button>
        </nav>
      </div>
      <RFQModal isOpen={modalOpen} onClose={() => setModalOpen(false)} />
      <EditRFQModal
        isOpen={editModalOpen}
        onClose={() => setEditModalOpen(false)}
        rfq={rfqToEdit}
      />
      <WarningRFQModal
        isOpen={warningModalOpen}
        onClose={cancelDelete}
        rfqId={rfqToDelete ? rfqs.find((o) => o._id === rfqToDelete)?._id : ''}
        onConfirm={confirmDelete}
      />
      <AddQuotationToRFQ
        isOpen={addQuotationModalOpen}
        onClose={() => setAddQuotationModalOpen(false)}
        rfqId={selectedRFQ ? selectedRFQ._id : null}
      />
    </div>
  )
}

export default RFQList
