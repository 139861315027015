// actions/healthCampActions.js
import axios from 'axios'

import { API_URL, API_KEY } from '../config/config'
import { toast } from 'react-toastify'
import {
  ADD_HEALTH_CAMP_FAILURE,
  ADD_HEALTH_CAMP_REQUEST,
  ADD_HEALTH_CAMP_SUCCESS,
  DELETE_HEALTH_CAMP_FAILURE,
  DELETE_HEALTH_CAMP_REQUEST,
  DELETE_HEALTH_CAMP_SUCCESS,
  GET_ALL_HEALTH_CAMPS_FAILURE,
  GET_ALL_HEALTH_CAMPS_REQUEST,
  GET_ALL_HEALTH_CAMPS_SUCCESS,
  UPDATE_HEALTH_CAMP_FAILURE,
  UPDATE_HEALTH_CAMP_REQUEST,
  UPDATE_HEALTH_CAMP_SUCCESS,
} from '../constants/healthCampConstants'

const axiosConfig = {
  headers: {
    'X-API-KEY': API_KEY,
  },
}

// Add Health Camp
export const addHealthCamp = (healthCampData) => async (dispatch) => {
  dispatch({ type: ADD_HEALTH_CAMP_REQUEST })
  try {
    const res = await axios.post(
      `${API_URL}/healthcamps/healthcamp`,
      healthCampData,
      axiosConfig
    )
    dispatch({
      type: ADD_HEALTH_CAMP_SUCCESS,
      payload: res.data,
    })
    toast.success('Health camp added successfully')
  } catch (error) {
    dispatch({
      type: ADD_HEALTH_CAMP_FAILURE,
      payload: error.response.data,
    })
    toast.error('Failed to add health camp')
  }
}

// Update Health Camp
export const updateHealthCamp =
  (healthCampId, healthCampData) => async (dispatch) => {
    dispatch({ type: UPDATE_HEALTH_CAMP_REQUEST })
    try {
      const res = await axios.put(
        `${API_URL}/healthcamps/healthcamps/${healthCampId}`,
        healthCampData,
        axiosConfig
      )
      dispatch({
        type: UPDATE_HEALTH_CAMP_SUCCESS,
        payload: res.data,
      })
      toast.success('Health camp updated successfully')
    } catch (error) {
      dispatch({
        type: UPDATE_HEALTH_CAMP_FAILURE,
        payload: error.response.data,
      })
      toast.error('Failed to update health camp')
    }
  }

// Get All Health Camps
export const getAllHealthCamps =
  ({ page = 1, size = 10 } = {}) =>
  async (dispatch) => {
    dispatch({ type: GET_ALL_HEALTH_CAMPS_REQUEST })
    try {
      const { data } = await axios.get(`${API_URL}/healthcamps/healthcamps`, {
        params: { page, size },
        ...axiosConfig,
      })
      dispatch({
        type: GET_ALL_HEALTH_CAMPS_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: GET_ALL_HEALTH_CAMPS_FAILURE,
        payload: error.response.data,
      })
    }
  }

// Delete Health Camp
export const deleteHealthCamp = (healthCampId) => async (dispatch) => {
  dispatch({ type: DELETE_HEALTH_CAMP_REQUEST })
  try {
    await axios.delete(
      `${API_URL}/healthcamps/healthcamps/${healthCampId}`,
      axiosConfig
    )
    dispatch({
      type: DELETE_HEALTH_CAMP_SUCCESS,
      payload: healthCampId,
    })
    toast.success('Health camp deleted successfully')
  } catch (error) {
    dispatch({
      type: DELETE_HEALTH_CAMP_FAILURE,
      payload: error.response.data,
    })
    toast.error('Failed to delete health camp')
  }
}
