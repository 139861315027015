import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { addQuotationToRFQ } from '../../actions/rfqActions'

const AddQuotationToRFQ = ({ isOpen, onClose, rfqId }) => {
  const dispatch = useDispatch()

  // Initial state setup based on parameters
  const [phoneNumber, setPhoneNumber] = useState('')
  const [price, setPrice] = useState('')
  const [validUntil, setValidUntil] = useState('')
  const [quotationId, setQuotationId] = useState('')

  const handleSubmit = async (e) => {
    e.preventDefault()
    console.log('RFQ ID:', rfqId)
    if (!rfqId) {
      toast.error('RFQ ID is missing')
      return
    }
    const quotationData = { phoneNumber, price, validUntil, quotationId }

    try {
      await dispatch(addQuotationToRFQ(rfqId, quotationData))
      toast.success('Quotation added to RFQ successfully')
      onClose()
    } catch (error) {
      toast.error('Failed to add quotation to RFQ')
    }
  }

  if (!isOpen) return null

  return (
    <>
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
        <div className="relative w-full max-w-md rounded-lg bg-white p-6 shadow-lg">
          <button
            onClick={onClose}
            className="absolute right-4 top-4 text-gray-500 hover:text-gray-700 focus:outline-none"
          >
            <FontAwesomeIcon icon={faTimes} size="lg" />
          </button>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label
                htmlFor="phoneNumber"
                className="block text-sm font-medium text-gray-700"
              >
                Supplier's Phone Number:
              </label>
              <input
                id="phoneNumber"
                type="text"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                required
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
            <div>
              <label
                htmlFor="price"
                className="block text-sm font-medium text-gray-700"
              >
                Price:
              </label>
              <input
                id="price"
                type="text"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                required
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
            <div>
              <label
                htmlFor="validUntil"
                className="block text-sm font-medium text-gray-700"
              >
                Valid Until:
              </label>
              <input
                id="validUntil"
                type="date"
                value={validUntil}
                onChange={(e) => setValidUntil(e.target.value)}
                required
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
            <div>
              <label
                htmlFor="quotationId"
                className="block text-sm font-medium text-gray-700"
              >
                Quotation ID:
              </label>
              <input
                id="quotationId"
                type="text"
                value={quotationId}
                onChange={(e) => setQuotationId(e.target.value)}
                required
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
            <button
              type="submit"
              className="w-full rounded-md bg-brandSecondary px-4 py-2 text-white hover:bg-brandPrimary focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
            >
              Add Quotation to RFQ
            </button>
          </form>
        </div>
      </div>
      <ToastContainer />
    </>
  )
}

export default AddQuotationToRFQ
