import React from 'react'
import { Routes, Route } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import AdminLogin from './components/Admin/Login'
import AdminRegistration from './components/Admin/Register'
import Dashboard from './components/Dashboard/Dashboard'
import AdminPage from './components/Dashboard/AdminPage'
import SuppliersPage from './components/Dashboard/SuppliersPage'
import OrdersPage from './components/Dashboard/OrdersPage'
import InventoryPage from './components/Dashboard/InventoryPage'
import RFQPage from './components/Dashboard/RFQPage'
import HealthcampsPage from './components/Dashboard/HealthcampsPage'
import EmployeesPage from './components/Dashboard/EmployeesPage'
import IssuingItemsPage from './components/Dashboard/IssuingItemsPage'
import ZuriAssetsPage from './components/Dashboard/ZuriAssetsPage'
import QuotationPage from './components/Dashboard/QuotationPage'
import Home from './components/Dashboard/Home'

function App() {
  return (
    <div>
      <ToastContainer position="top-center" />
      <Routes>
        <Route path="/" element={<AdminLogin />} />
        <Route path="/zuri_health/register" element={<AdminRegistration />} />
        <Route path="/dashboard" element={<Dashboard />}>
          <Route path="home" element={<Home />} />
          <Route path="admin" element={<AdminPage />} />
          <Route path="suppliers" element={<SuppliersPage />} />
          <Route path="orders" element={<OrdersPage />} />
          <Route path="inventory" element={<InventoryPage />} />
          <Route path="rfq" element={<RFQPage />} />
          <Route path="healthcamps" element={<HealthcampsPage />} />
          <Route path="employees" element={<EmployeesPage />} />
          <Route path="issuing-items" element={<IssuingItemsPage />} />
          <Route path="zuri-assets" element={<ZuriAssetsPage />} />
          <Route path="quotations" element={<QuotationPage />} />
        </Route>
      </Routes>
    </div>
  )
}

export default App
