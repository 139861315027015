// pages/SuppliersPage.js

import SupplierList from '../Suppliers/SupplierList'

const SuppliersPage = () => {
  return (
    <div>
      <SupplierList />
    </div>
  )
}

export default SuppliersPage
