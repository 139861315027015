import axios from 'axios'
import {
  ADD_ISSUED_ITEM_REQUEST,
  ADD_ISSUED_ITEM_SUCCESS,
  ADD_ISSUED_ITEM_FAILURE,
  UPDATE_ITEM_STATUS_REQUEST,
  UPDATE_ITEM_STATUS_SUCCESS,
  UPDATE_ITEM_STATUS_FAILURE,
  GET_ITEMS_REQUEST,
  GET_ITEMS_SUCCESS,
  GET_ITEMS_FAILURE,
  DELETE_ITEM_REQUEST,
  DELETE_ITEM_SUCCESS,
  DELETE_ITEM_FAILURE,
} from '../constants/issuedItemConstants'
import { API_URL, API_KEY } from '../config/config'
import { toast } from 'react-toastify'

const axiosConfig = {
  headers: {
    'X-API-KEY': API_KEY,
  },
}

// Add Issued Item
export const addIssuedItem = (itemData) => async (dispatch) => {
  dispatch({ type: ADD_ISSUED_ITEM_REQUEST })
  try {
    const res = await axios.post(
      `${API_URL}/zuri-items/new`,
      itemData,
      axiosConfig
    )
    dispatch({
      type: ADD_ISSUED_ITEM_SUCCESS,
      payload: res.data,
    })
    toast.success('Item added successfully')
  } catch (error) {
    dispatch({
      type: ADD_ISSUED_ITEM_FAILURE,
      payload: error.response.data,
    })
    toast.error('Failed to add item')
  }
}

// Update Item Status
export const updateItemStatus = (itemId, statusData) => async (dispatch) => {
  dispatch({ type: UPDATE_ITEM_STATUS_REQUEST })
  try {
    const res = await axios.put(
      `${API_URL}/zuri-items/items/${itemId}/status`,
      statusData,
      axiosConfig
    )
    dispatch({
      type: UPDATE_ITEM_STATUS_SUCCESS,
      payload: res.data,
    })
    toast.success('Item status updated successfully')
  } catch (error) {
    dispatch({
      type: UPDATE_ITEM_STATUS_FAILURE,
      payload: error.response.data,
    })
    toast.error('Failed to update item status')
  }
}

// Get All Items
export const getAllItems =
  ({ page = 1, size = 10 } = {}) =>
  async (dispatch) => {
    dispatch({ type: GET_ITEMS_REQUEST })
    try {
      const { data } = await axios.get(`${API_URL}/zuri-items/all`, {
        params: { page, size },
        ...axiosConfig,
      })
      dispatch({
        type: GET_ITEMS_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: GET_ITEMS_FAILURE,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
      toast.error('Failed to fetch items')
    }
  }

// Delete Item
export const deleteItem = (itemId) => async (dispatch) => {
  dispatch({ type: DELETE_ITEM_REQUEST })
  try {
    await axios.delete(`${API_URL}/zuri-items/items/${itemId}`, axiosConfig)
    dispatch({
      type: DELETE_ITEM_SUCCESS,
      payload: itemId,
    })
    toast.success('Item deleted successfully')
  } catch (error) {
    dispatch({
      type: DELETE_ITEM_FAILURE,
      payload: error.response.data,
    })
    toast.error('Failed to delete item')
  }
}
