import React from 'react'
import EmployeeList from '../Employees/EmployeeList'

function EmployeesPage() {
  return (
    <div>
      <EmployeeList />
    </div>
  )
}

export default EmployeesPage
