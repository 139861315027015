// RFQ Action Types
export const ADD_RFQ_REQUEST = 'ADD_RFQ_REQUEST'
export const ADD_RFQ_SUCCESS = 'ADD_RFQ_SUCCESS'
export const ADD_RFQ_FAILURE = 'ADD_RFQ_FAILURE'

export const EDIT_RFQ_REQUEST = 'EDIT_RFQ_REQUEST'
export const EDIT_RFQ_SUCCESS = 'EDIT_RFQ_SUCCESS'
export const EDIT_RFQ_FAILURE = 'EDIT_RFQ_FAILURE'

export const DELETE_RFQ_REQUEST = 'DELETE_RFQ_REQUEST'
export const DELETE_RFQ_SUCCESS = 'DELETE_RFQ_SUCCESS'
export const DELETE_RFQ_FAILURE = 'DELETE_RFQ_FAILURE'

export const GET_ALL_RFQS_REQUEST = 'GET_ALL_RFQS_REQUEST'
export const GET_ALL_RFQS_SUCCESS = 'GET_ALL_RFQS_SUCCESS'
export const GET_ALL_RFQS_FAILURE = 'GET_ALL_RFQS_FAILURE'

export const TRACK_RFQS_REQUEST = 'TRACK_RFQS_REQUEST'
export const TRACK_RFQS_SUCCESS = 'TRACK_RFQS_SUCCESS'
export const TRACK_RFQS_FAILURE = 'TRACK_RFQS_FAILURE'

// Quotation Action Types
export const ADD_QUOTATION_REQUEST = 'ADD_QUOTATION_REQUEST'
export const ADD_QUOTATION_SUCCESS = 'ADD_QUOTATION_SUCCESS'
export const ADD_QUOTATION_FAILURE = 'ADD_QUOTATION_FAILURE'

export const CREATE_QUOTATION_REQUEST = 'CREATE_QUOTATION_REQUEST'
export const CREATE_QUOTATION_SUCCESS = 'CREATE_QUOTATION_SUCCESS'
export const CREATE_QUOTATION_FAILURE = 'CREATE_QUOTATION_FAILURE'

export const UPDATE_QUOTATION_REQUEST = 'UPDATE_QUOTATION_REQUEST'
export const UPDATE_QUOTATION_SUCCESS = 'UPDATE_QUOTATION_SUCCESS'
export const UPDATE_QUOTATION_FAILURE = 'UPDATE_QUOTATION_FAILURE'

export const DELETE_QUOTATION_REQUEST = 'DELETE_QUOTATION_REQUEST'
export const DELETE_QUOTATION_SUCCESS = 'DELETE_QUOTATION_SUCCESS'
export const DELETE_QUOTATION_FAILURE = 'DELETE_QUOTATION_FAILURE'

export const GET_RFQ_QUOTATIONS_REQUEST = 'GET_RFQ_QUOTATIONS_REQUEST'
export const GET_RFQ_QUOTATIONS_SUCCESS = 'GET_RFQ_QUOTATIONS_SUCCESS'
export const GET_RFQ_QUOTATIONS_FAILURE = 'GET_RFQ_QUOTATIONS_FAILURE'
