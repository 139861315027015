import { createStore, combineReducers, applyMiddleware } from 'redux'
import { thunk } from 'redux-thunk'
import { composeWithDevTools } from '@redux-devtools/extension'
import authReducer from './reducers/userReducers'
import supplierReducer from './reducers/supplierReducer'
import purchaseOrderReducer from './reducers/purchaseOrderReducer'
import inventoryReducer from './reducers/inventoryReducer'
import { rfqReducer } from './reducers/rfqReducer'
import healthCampReducer from './reducers/healthCampReducer'
import employeeReducer from './reducers/employeeReducer'
import issuedItemReducer from './reducers/issuedItemReducer'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { encryptTransform } from 'redux-persist-transform-encrypt'
import { SECRET_KEY } from './config/config'

const reducer = combineReducers({
  auth: authReducer,
  suppliers: supplierReducer,
  purchaseOrders: purchaseOrderReducer,
  inventory: inventoryReducer,
  rfqs: rfqReducer,
  healthCamps: healthCampReducer,
  employees: employeeReducer,
  items: issuedItemReducer,
})

const middleware = [thunk]

const persistConfig = {
  key: 'root',
  storage,
  transforms: [
    encryptTransform({
      secretKey: SECRET_KEY,
      onError: function (error) {
        // Handle the error.
        console.error('Encryption error:', error)
      },
    }),
  ],
}

const persistedReducer = persistReducer(persistConfig, reducer)

const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(...middleware))
)

const persistor = persistStore(store)

export { store, persistor }
