import React from 'react'
import UserList from '../Admin/UserList'

function AdminPage() {
  return (
    <div>
      <UserList />
    </div>
  )
}

export default AdminPage
