import React from 'react'

function HomeLogo() {
  return (
    <div className="flex min-h-screen flex-col items-center justify-center bg-gray-100 p-6 text-center">
      <img
        src="https://healthcamp-products.s3.us-east-2.amazonaws.com/Frame+6322.svg"
        alt="Home Logo"
        className="mb-4 w-1/2 max-w-4xl"
      />
      {/* <h1 className="text-2xl font-bold mb-2">Welcome!</h1> */}
      <p className="text-lg text-gray-700">
        You have successfully accessed the site containing sensitive company
        information. Please proceed with caution and ensure that you have the
        necessary permissions to view this content.
      </p>
    </div>
  )
}

export default HomeLogo
