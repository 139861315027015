import {
  ADD_SUPPLIER_REQUEST,
  ADD_SUPPLIER_SUCCESS,
  ADD_SUPPLIER_FAILURE,
  EDIT_SUPPLIER_REQUEST,
  EDIT_SUPPLIER_SUCCESS,
  EDIT_SUPPLIER_FAILURE,
  DELETE_SUPPLIER_REQUEST,
  DELETE_SUPPLIER_SUCCESS,
  DELETE_SUPPLIER_FAILURE,
  GET_ALL_SUPPLIERS_REQUEST,
  GET_ALL_SUPPLIERS_SUCCESS,
  GET_ALL_SUPPLIERS_FAILURE,
  SEARCH_SUPPLIER_REQUEST,
  SEARCH_SUPPLIER_SUCCESS,
  SEARCH_SUPPLIER_FAILURE,
} from '../constants/supplierConstants'
import { toast } from 'react-toastify'

const initialState = {
  suppliers: [],
  metadata: {}, // Add metadata to the state
  loading: false,
  errors: {},
}

export default function supplierReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_SUPPLIER_REQUEST:
    case EDIT_SUPPLIER_REQUEST:
    case DELETE_SUPPLIER_REQUEST:
    case GET_ALL_SUPPLIERS_REQUEST:
    case SEARCH_SUPPLIER_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case ADD_SUPPLIER_SUCCESS:
      toast.success('Supplier added successfully')
      return {
        ...state,
        suppliers: [...state.suppliers, action.payload],
        loading: false,
      }
    case EDIT_SUPPLIER_SUCCESS:
      toast.success('Supplier updated successfully')
      return {
        ...state,
        suppliers: state.suppliers.map((supplier) =>
          supplier._id === action.payload._id ? action.payload : supplier
        ),
        loading: false,
      }
    case DELETE_SUPPLIER_SUCCESS:
      toast.success('Supplier deleted successfully')
      return {
        ...state,
        suppliers: state.suppliers.filter(
          (supplier) => supplier._id !== action.payload
        ),
        loading: false,
      }
    case GET_ALL_SUPPLIERS_SUCCESS:
      return {
        ...state,
        suppliers: action.payload.suppliers,
        metadata: action.payload.metadata,
        loading: false,
      }
    case SEARCH_SUPPLIER_SUCCESS:
      return {
        ...state,
        suppliers: action.payload,
        loading: false,
      }
    case ADD_SUPPLIER_FAILURE:
      toast.error(`Failed to add supplier: ${action.payload.message}`)
      return {
        ...state,
        errors: action.payload,
        loading: false,
      }
    case EDIT_SUPPLIER_FAILURE:
      toast.error(`Failed to update supplier: ${action.payload.message}`)
      return {
        ...state,
        errors: action.payload,
        loading: false,
      }
    case DELETE_SUPPLIER_FAILURE:
      toast.error(`Failed to delete supplier: ${action.payload.message}`)
      return {
        ...state,
        errors: action.payload,
        loading: false,
      }
    case GET_ALL_SUPPLIERS_FAILURE:
      toast.error(`Failed to fetch suppliers: ${action.payload.message}`)
      return {
        ...state,
        errors: action.payload,
        loading: false,
      }
    case SEARCH_SUPPLIER_FAILURE:
      toast.error(`Failed to search supplier: ${action.payload.message}`)
      return {
        ...state,
        errors: action.payload,
        loading: false,
      }
    default:
      return state
  }
}
