import axios from 'axios'
import {
  ADD_RFQ_REQUEST,
  ADD_RFQ_SUCCESS,
  ADD_RFQ_FAILURE,
  EDIT_RFQ_REQUEST,
  EDIT_RFQ_SUCCESS,
  EDIT_RFQ_FAILURE,
  DELETE_RFQ_REQUEST,
  DELETE_RFQ_SUCCESS,
  DELETE_RFQ_FAILURE,
  TRACK_RFQS_REQUEST,
  TRACK_RFQS_SUCCESS,
  TRACK_RFQS_FAILURE,
  ADD_QUOTATION_REQUEST,
  ADD_QUOTATION_SUCCESS,
  ADD_QUOTATION_FAILURE,
  CREATE_QUOTATION_REQUEST,
  CREATE_QUOTATION_SUCCESS,
  CREATE_QUOTATION_FAILURE,
  UPDATE_QUOTATION_REQUEST,
  UPDATE_QUOTATION_SUCCESS,
  UPDATE_QUOTATION_FAILURE,
  DELETE_QUOTATION_REQUEST,
  DELETE_QUOTATION_SUCCESS,
  DELETE_QUOTATION_FAILURE,
  GET_RFQ_QUOTATIONS_REQUEST,
  GET_RFQ_QUOTATIONS_SUCCESS,
  GET_RFQ_QUOTATIONS_FAILURE,
} from '../constants/rfqConstants'
import { API_URL, API_KEY } from '../config/config'
import { toast } from 'react-toastify'

const axiosConfig = {
  headers: {
    'X-API-KEY': API_KEY,
  },
}

// Add RFQ
export const addRFQ = (rfqData) => async (dispatch) => {
  dispatch({ type: ADD_RFQ_REQUEST })
  try {
    const res = await axios.post(`${API_URL}/RFQ/new_rfq`, rfqData, axiosConfig)
    dispatch({ type: ADD_RFQ_SUCCESS, payload: { rfq: res.data.rfq } })
    toast.success('RFQ added successfully')
  } catch (error) {
    dispatch({ type: ADD_RFQ_FAILURE, payload: error.response.data })
    toast.error('Failed to add RFQ')
  }
}

// Edit RFQ
export const editRFQ = (rfqId, rfqData) => async (dispatch) => {
  dispatch({ type: EDIT_RFQ_REQUEST })
  try {
    const res = await axios.put(
      `${API_URL}/RFQ/rfq/${rfqId}`,
      rfqData,
      axiosConfig
    )
    dispatch({ type: EDIT_RFQ_SUCCESS, payload: { rfq: res.data.rfq } })
    toast.success('RFQ updated successfully')
  } catch (error) {
    dispatch({ type: EDIT_RFQ_FAILURE, payload: error.response.data })
    toast.error('Failed to update RFQ')
  }
}

// Delete RFQ
export const deleteRFQ = (rfqId) => async (dispatch) => {
  dispatch({ type: DELETE_RFQ_REQUEST })
  try {
    await axios.delete(`${API_URL}/RFQ/rfq/${rfqId}`, axiosConfig)
    dispatch({ type: DELETE_RFQ_SUCCESS, payload: rfqId })
    toast.success('RFQ deleted successfully')
  } catch (error) {
    dispatch({ type: DELETE_RFQ_FAILURE, payload: error.response.data })
    toast.error('Failed to delete RFQ')
  }
}

// Track and Compare RFQs
export const trackRFQs =
  ({ page = 1, size = 10 } = {}) =>
  async (dispatch) => {
    dispatch({ type: TRACK_RFQS_REQUEST })
    try {
      const res = await axios.get(`${API_URL}/RFQ/rfq/track`, {
        params: { page, size },
        ...axiosConfig,
      })
      dispatch({ type: TRACK_RFQS_SUCCESS, payload: res.data })
    } catch (error) {
      dispatch({ type: TRACK_RFQS_FAILURE, payload: error.response.data })
    }
  }

// Add Quotation to RFQ
export const addQuotationToRFQ = (rfqId, quotationData) => async (dispatch) => {
  dispatch({ type: ADD_QUOTATION_REQUEST })
  try {
    const res = await axios.post(
      `${API_URL}/RFQ/rfq/${rfqId}/quotation`,
      quotationData,
      axiosConfig
    )
    dispatch({
      type: ADD_QUOTATION_SUCCESS,
      payload: { quotation: res.data.quotation },
    })
    toast.success('Quotation added to RFQ successfully')
  } catch (error) {
    dispatch({ type: ADD_QUOTATION_FAILURE, payload: error.response.data })
    toast.error('Failed to add quotation to RFQ')
  }
}

// Create Quotation
export const createQuotation = (quotationData) => async (dispatch) => {
  dispatch({ type: CREATE_QUOTATION_REQUEST })
  try {
    const res = await axios.post(
      `${API_URL}/RFQ/quotation`,
      quotationData,
      axiosConfig
    )
    dispatch({
      type: CREATE_QUOTATION_SUCCESS,
      payload: { quotation: res.data.quotation },
    })
    toast.success('Quotation created successfully')
  } catch (error) {
    dispatch({ type: CREATE_QUOTATION_FAILURE, payload: error.response.data })
    toast.error('Failed to create quotation')
  }
}

// Update Quotation
export const updateQuotation =
  (quotationId, quotationData) => async (dispatch) => {
    dispatch({ type: UPDATE_QUOTATION_REQUEST })
    try {
      const res = await axios.put(
        `${API_URL}/RFQ/quotation/${quotationId}`,
        quotationData,
        axiosConfig
      )
      dispatch({
        type: UPDATE_QUOTATION_SUCCESS,
        payload: { quotation: res.data.quotation },
      })
      toast.success('Quotation updated successfully')
    } catch (error) {
      dispatch({ type: UPDATE_QUOTATION_FAILURE, payload: error.response.data })
      toast.error('Failed to update quotation')
    }
  }

// Delete Quotation
export const deleteQuotation = (quotationId) => async (dispatch) => {
  dispatch({ type: DELETE_QUOTATION_REQUEST })
  try {
    await axios.delete(`${API_URL}/RFQ/quotation/${quotationId}`, axiosConfig)
    dispatch({ type: DELETE_QUOTATION_SUCCESS, payload: quotationId })
    toast.success('Quotation deleted successfully')
  } catch (error) {
    dispatch({ type: DELETE_QUOTATION_FAILURE, payload: error.response.data })
    toast.error('Failed to delete quotation')
  }
}

// Get Quotations for a specific RFQ
export const getQuotations =
  ({ page = 1, size = 10 }) =>
  async (dispatch) => {
    dispatch({ type: GET_RFQ_QUOTATIONS_REQUEST })

    try {
      const { data } = await axios.get(`${API_URL}/RFQ/quotations`, {
        params: { page, size },
        ...axiosConfig,
      })
      //   console.log('API Response Data:', data); // Add this log
      dispatch({
        type: GET_RFQ_QUOTATIONS_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: GET_RFQ_QUOTATIONS_FAILURE,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }
