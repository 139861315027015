import React from 'react'
import HealthCampList from '../HealthCamp/HealthCampList'

function HealthcampsPage() {
  return (
    <div>
      <HealthCampList />
    </div>
  )
}

export default HealthcampsPage
