import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { addPurchaseOrder } from '../../actions/purchaseOrderActions'

const PurchaseOrderModal = ({ isOpen, onClose }) => {
  const dispatch = useDispatch()

  const [supplierPhoneNumber, setSupplierPhoneNumber] = useState('')
  const [orderDate, setOrderDate] = useState('')
  const [status, setStatus] = useState('pending')
  const [invoice, setInvoice] = useState(null)
  const [receipt, setReceipt] = useState(null)

  const handleSubmit = async (e) => {
    e.preventDefault()
    const formData = new FormData()
    formData.append('supplierPhoneNumber', supplierPhoneNumber)
    formData.append('orderDate', orderDate)
    formData.append('status', status)
    if (invoice) {
      formData.append('invoice', invoice)
    }
    if (receipt) {
      formData.append('receipt', receipt)
    }
    try {
      await dispatch(addPurchaseOrder(formData))
      toast.success('Purchase order added successfully')
      onClose()
    } catch (error) {
      toast.error('Failed to add purchase order')
    }
  }

  if (!isOpen) return null

  return (
    <>
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
        <div className="relative w-full max-w-md rounded-lg bg-white p-6 shadow-lg">
          <button
            onClick={onClose}
            className="absolute right-4 top-4 text-gray-500 hover:text-gray-700 focus:outline-none"
          >
            <FontAwesomeIcon icon={faTimes} size="lg" />
          </button>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label
                htmlFor="supplierPhoneNumber"
                className="block text-sm font-medium text-gray-700"
              >
                Supplier Phone Number:
              </label>
              <input
                id="supplierPhoneNumber"
                type="text"
                value={supplierPhoneNumber}
                onChange={(e) => setSupplierPhoneNumber(e.target.value)}
                required
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
            <div>
              <label
                htmlFor="orderDate"
                className="block text-sm font-medium text-gray-700"
              >
                Order Date:
              </label>
              <input
                id="orderDate"
                type="date"
                value={orderDate}
                onChange={(e) => setOrderDate(e.target.value)}
                required
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
            <div>
              <label
                htmlFor="status"
                className="block text-sm font-medium text-gray-700"
              >
                Status:
              </label>
              <select
                id="status"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                required
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              >
                <option value="pending">Pending</option>
                <option value="approved">Approved</option>
                <option value="received">Received</option>
              </select>
            </div>
            <div>
              <label
                htmlFor="invoice"
                className="block text-sm font-medium text-gray-700"
              >
                Invoice:
              </label>
              <input
                id="invoice"
                type="file"
                onChange={(e) => setInvoice(e.target.files[0])}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
            <div>
              <label
                htmlFor="receipt"
                className="block text-sm font-medium text-gray-700"
              >
                Receipt:
              </label>
              <input
                id="receipt"
                type="file"
                onChange={(e) => setReceipt(e.target.files[0])}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
            <button
              type="submit"
              className="w-full rounded-md bg-brandSecondary px-4 py-2 text-white hover:bg-brandPrimary focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
            >
              Add Purchase Order
            </button>
          </form>
        </div>
      </div>
      <ToastContainer />
    </>
  )
}

export default PurchaseOrderModal
