export const ADD_SUPPLIER_REQUEST = 'ADD_SUPPLIER_REQUEST'
export const ADD_SUPPLIER_SUCCESS = 'ADD_SUPPLIER_SUCCESS'
export const ADD_SUPPLIER_FAILURE = 'ADD_SUPPLIER_FAILURE'

export const EDIT_SUPPLIER_REQUEST = 'EDIT_SUPPLIER_REQUEST'
export const EDIT_SUPPLIER_SUCCESS = 'EDIT_SUPPLIER_SUCCESS'
export const EDIT_SUPPLIER_FAILURE = 'EDIT_SUPPLIER_FAILURE'

export const DELETE_SUPPLIER_REQUEST = 'DELETE_SUPPLIER_REQUEST'
export const DELETE_SUPPLIER_SUCCESS = 'DELETE_SUPPLIER_SUCCESS'
export const DELETE_SUPPLIER_FAILURE = 'DELETE_SUPPLIER_FAILURE'

export const GET_ALL_SUPPLIERS_REQUEST = 'GET_ALL_SUPPLIERS_REQUEST'
export const GET_ALL_SUPPLIERS_SUCCESS = 'GET_ALL_SUPPLIERS_SUCCESS'
export const GET_ALL_SUPPLIERS_FAILURE = 'GET_ALL_SUPPLIERS_FAILURE'

export const SEARCH_SUPPLIER_REQUEST = 'SEARCH_SUPPLIER_REQUEST'
export const SEARCH_SUPPLIER_SUCCESS = 'SEARCH_SUPPLIER_SUCCESS'
export const SEARCH_SUPPLIER_FAILURE = 'SEARCH_SUPPLIER_FAILURE'
