import React from 'react'
import QuotationList from '../RFQ/QuotationList'

function QuotationPage() {
  return (
    <div>
      <QuotationList />
    </div>
  )
}

export default QuotationPage
