// RepairModal.jsx
import React from 'react'

const RepairModal = ({ isOpen, onClose, repair }) => {
  if (!isOpen) return null

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div className="w-1/3 rounded-lg bg-white p-6 shadow-lg">
        <h2 className="mb-4 text-xl font-semibold">Repair Details</h2>
        <div className="mb-4">
          <strong>Repair Shop:</strong> {repair.repairShop}
        </div>
        <div className="mb-4">
          <strong>Quotation:</strong> ${repair.quotation}
        </div>
        <div className="mb-4">
          <strong>Repair Status:</strong> {repair.repairStatus}
        </div>
        <div className="mb-4">
          <strong>Repair Date:</strong>{' '}
          {new Date(repair.repairDate).toLocaleDateString()}
        </div>
        <button
          onClick={onClose}
          className="btn rounded bg-brandPrimary px-4 py-2 text-white"
        >
          Close
        </button>
      </div>
    </div>
  )
}

export default RepairModal
