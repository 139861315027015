import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  deletePurchaseOrder,
  getAllPurchaseOrders,
} from '../../actions/purchaseOrderActions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEllipsisV,
  faEdit,
  faTrash,
  faPlus,
  faSearch,
} from '@fortawesome/free-solid-svg-icons'
import OrderModal from './PurchaseOrderModal'
import WarningModal from './WarningModal'
import EditOrderModal from './EditPurchaseOrderModal'

const OrderList = () => {
  const dispatch = useDispatch()
  const { purchaseOrders, loading, metadata } = useSelector(
    (state) => state.purchaseOrders
  )

  const [currentPage, setCurrentPage] = useState(1)
  const pageSize = 10

  useEffect(() => {
    dispatch(getAllPurchaseOrders({ page: currentPage, size: pageSize }))
  }, [dispatch, currentPage])

  const [menuOpen, setMenuOpen] = useState(null)
  const menuRef = useRef(null)
  const deleteButtonRef = useRef(null)
  const editButtonRef = useRef(null)
  const [modalOpen, setModalOpen] = useState(false)
  const [warningModalOpen, setWarningModalOpen] = useState(false)
  const [orderToDelete, setOrderToDelete] = useState(null)
  const [searchQuery, setSearchQuery] = useState('')
  const [editModalOpen, setEditModalOpen] = useState(false)
  const [orderToEdit, setOrderToEdit] = useState(null)

  const toggleMenu = (id) => {
    setMenuOpen(menuOpen === id ? null : id)
  }

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber)
  }

  const handleEdit = (order) => {
    setOrderToEdit(order)
    setEditModalOpen(true)
    setMenuOpen(null)
  }

  const handleDelete = (id) => {
    console.log(`Delete button clicked for order ID: ${id}`)
    setOrderToDelete(id)
    setWarningModalOpen(true)
  }

  const confirmDelete = () => {
    if (orderToDelete) {
      dispatch(deletePurchaseOrder(orderToDelete))
      setOrderToDelete(null)
      setWarningModalOpen(false)
    }
  }

  const cancelDelete = () => {
    setOrderToDelete(null)
    setWarningModalOpen(false)
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        deleteButtonRef.current &&
        !deleteButtonRef.current.contains(event.target) &&
        editButtonRef.current &&
        !editButtonRef.current.contains(event.target)
      ) {
        setMenuOpen(null)
        setEditModalOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  if (loading) return <p className="text-center text-lg">Loading...</p>

  return (
    <div className="container mx-auto p-4">
      <div className="mb-4 flex items-center justify-between">
        <button
          onClick={() => setModalOpen(true)}
          className="btn flex items-center rounded bg-brandPrimary px-4 py-2 text-white"
        >
          <FontAwesomeIcon icon={faPlus} className="mr-2" /> Add Order
        </button>
        <div className="flex items-center space-x-2">
          <input
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="rounded-lg border border-gray-300 px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <button className="btn flex items-center rounded bg-gray-200 px-4 py-2 text-gray-600 hover:bg-gray-300">
            <FontAwesomeIcon icon={faSearch} className="mr-2" /> Search
          </button>
        </div>
      </div>
      <div className="overflow-x-auto">
        <table className="min-w-full rounded-lg border border-gray-200 bg-white shadow-md">
          <thead className="bg-gray-100 text-left text-gray-600">
            <tr>
              <th className="px-4 py-2">Order ID</th>
              <th className="px-4 py-2">Supplier</th>
              <th className="px-4 py-2">Order Date</th>
              <th className="px-4 py-2">Status</th>
              <th className="px-4 py-2">Documents</th>
              <th className="px-4 py-2">Actions</th>
            </tr>
          </thead>
          <tbody>
            {purchaseOrders
              .filter(
                (purchaseOrders) =>
                  purchaseOrders.supplier &&
                  purchaseOrders.supplier.name
                    .toLowerCase()
                    .includes(searchQuery.toLowerCase())
              )
              .map((order) => (
                <tr
                  key={order._id}
                  className="border-b border-gray-200 hover:bg-gray-50"
                >
                  <td className="px-4 py-2">{order._id.substring(0, 6)}</td>
                  <td className="px-4 py-2">
                    {order.supplier ? order.supplier.name : 'N/A'}
                  </td>
                  <td className="px-4 py-2">
                    {new Date(order.orderDate).toLocaleDateString()}
                  </td>
                  <td className="px-4 py-2">{order.status}</td>
                  <td className="px-4 py-2">
                    <a
                      href={order.documents.invoice}
                      className="text-blue-500 hover:underline"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Invoice
                    </a>
                    <br />
                    <a
                      href={order.documents.receipt}
                      className="text-blue-500 hover:underline"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Receipt
                    </a>
                  </td>
                  <td className="px-4 py-2">
                    <div className="relative flex items-center" ref={menuRef}>
                      <button
                        onClick={() => toggleMenu(order._id)}
                        className="text-gray-600 hover:text-gray-900 focus:outline-none"
                      >
                        <FontAwesomeIcon icon={faEllipsisV} />
                      </button>
                      {menuOpen === order._id && (
                        <div className="absolute right-0 z-10 mt-1 flex items-center space-x-2">
                          <button
                            ref={editButtonRef}
                            onClick={() => handleEdit(order)}
                            className="block flex items-center py-2 text-gray-700 hover:bg-gray-100"
                          >
                            <FontAwesomeIcon icon={faEdit} className="ml-1" />
                          </button>
                          <button
                            ref={deleteButtonRef}
                            onClick={() => handleDelete(order._id)}
                            className="block flex items-center py-2 text-gray-700 hover:bg-gray-100"
                          >
                            <FontAwesomeIcon
                              icon={faTrash}
                              className="ml-1 text-red-600"
                            />
                          </button>
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <div className="mt-4 flex items-center justify-between">
        <button
          onClick={() => handlePageChange(metadata.current_page - 1)}
          disabled={!metadata.has_previous_page}
          className="btn rounded bg-gray-200 px-4 py-2 text-gray-600 hover:bg-gray-300"
        >
          Previous
        </button>
        <span className="text-gray-700">
          Page {metadata.current_page} of {metadata.last_page}
        </span>
        <button
          onClick={() => handlePageChange(metadata.current_page + 1)}
          disabled={!metadata.has_next_page}
          className="btn rounded bg-gray-200 px-4 py-2 text-gray-600 hover:bg-gray-300"
        >
          Next
        </button>
      </div>
      <OrderModal isOpen={modalOpen} onClose={() => setModalOpen(false)} />
      <EditOrderModal
        isOpen={editModalOpen}
        onClose={() => setEditModalOpen(false)}
        order={orderToEdit}
      />
      <WarningModal
        isOpen={warningModalOpen}
        onClose={cancelDelete}
        itemName={
          orderToDelete
            ? purchaseOrders.find((o) => o._id === orderToDelete)?.order._id
            : ''
        }
        onConfirm={confirmDelete}
      />
    </div>
  )
}

export default OrderList
