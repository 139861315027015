// src/constants/departmentRoles.js

export const departmentRoles = {
  'Human Resource': [
    'HR Manager',
    'HR Assistant',
    'Recruiter',
    'HR Generalist',
    'HR Specialist',
    'Talent Acquisition Manager',
    'Employee Relations Manager',
    'Compensation and Benefits Specialist',
    'Learning and Development Manager',
    'HR Business Partner',
    'Payroll Specialist',
    'HR Coordinator',
    'Organizational Development Specialist',
    'HR Director',
    'Compliance Specialist',
  ],
  Medical: [
    'Lab Technologist',
    'Nurse',
    'Pharmacist',
    'Doctor',
    'Radiologist',
    'Medical Technician',
    'Surgeon',
    'Anesthesiologist',
    'Emergency Medical Technician (EMT)',
    'Clinical Psychologist',
    'Physical Therapist',
    'Occupational Therapist',
    'Speech-Language Pathologist',
    'Medical Records Technician',
    'Healthcare Administrator',
  ],
  Engineering: [
    'DevOps Engineer',
    'Backend Developer',
    'Frontend Developer',
    'CTO',
    'VAS Engineer',
    'Android Developer',
    'Web Developer',
    'iOS Developer',
    'Data Scientist',
    'Data Analyst',
    'UX/UI Designer',
    'Product Manager',
    'QA Engineer (Quality Assurance)',
    'Security Engineer',
    'Site Reliability Engineer (SRE)',
    'Business Analyst',
    'Cloud Engineer',
    'Technical Project Manager',
    'Network Engineer',
    'Systems Administrator',
    'Full Stack Developer',
  ],
  Marketing: [
    'Marketing Manager',
    'SEO Specialist',
    'Content Creator',
    'Videographer',
    'Social Media Manager',
    'Digital Marketing Specialist',
    'Email Marketing Specialist',
    'PPC (Pay-Per-Click) Specialist',
    'Marketing Analyst',
    'Brand Manager',
    'Growth Hacker',
    'Graphic Designer',
    'PR (Public Relations) Specialist',
  ],
  Sales: [
    'Sales Manager',
    'Sales Executive',
    'Account Manager',
    'Business Development Manager',
    'Sales Representative',
    'Inside Sales Representative',
    'Outside Sales Representative',
    'Sales Operations Manager',
    'Sales Analyst',
    'Customer Success Manager',
    'Sales Engineer',
    'Key Account Manager',
    'Regional Sales Manager',
    'Retail Sales Associate',
  ],
  Finance: [
    'Accountant',
    'Financial Analyst',
    'Finance Manager',
    'Chief Financial Officer (CFO)',
    'Controller',
    'Treasurer',
    'Tax Specialist',
    'Auditor',
    'Investment Analyst',
    'Budget Analyst',
    'Financial Planner',
    'Accounts Payable Specialist',
    'Accounts Receivable Specialist',
    'Risk Manager',
  ],
  'Customer Support': [
    'Support Manager',
    'Customer Service Representative',
    'Technical Support Specialist',
    'Customer Support Specialist',
    'Help Desk Technician',
    'Customer Success Manager',
    'Support Analyst',
    'Customer Experience Manager',
    'Support Team Lead',
    'Onboarding Specialist',
    'Service Delivery Manager',
    'Chat Support Agent',
    'Call Center Representative',
  ],
  Procurement: [
    'Procurement Manager',
    'Purchasing Agent',
    'Supply Chain Analyst',
    'Procurement Specialist',
    'Buyer',
    'Vendor Manager',
    'Contract Manager',
    'Category Manager',
    'Sourcing Specialist',
    'Logistics Coordinator',
    'Procurement Analyst',
    'Materials Manager',
    'Supplier Quality Engineer',
  ],
}
