import React from 'react'
import ItemList from '../IssuedItems/ItemList'

function IssuingItemsPage() {
  return (
    <div>
      <ItemList />
    </div>
  )
}

export default IssuingItemsPage
