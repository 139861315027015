import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { addHealthCamp } from '../../actions/healthCampActions' // Import the action for adding health camps

const HealthCampModal = ({ isOpen, onClose }) => {
  const dispatch = useDispatch()

  const [name, setName] = useState('')
  const [location, setLocation] = useState('')
  const [date, setDate] = useState('')
  const [items, setItems] = useState([
    { itemName: '', quantity: '', unit: '', specifications: '' },
  ])
  const [notes, setNotes] = useState('')

  const handleItemChange = (index, event) => {
    const newItems = items.slice()
    newItems[index][event.target.name] = event.target.value
    setItems(newItems)
  }

  const handleAddItem = () => {
    setItems([
      ...items,
      { itemName: '', quantity: '', unit: '', specifications: '' },
    ])
  }

  const handleRemoveItem = (index) => {
    setItems(items.filter((_, i) => i !== index))
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const healthCampData = { name, location, date, items, notes }
    try {
      await dispatch(addHealthCamp(healthCampData))
      toast.success('Health camp added successfully')
      onClose()
    } catch (error) {
      toast.error('Failed to add health camp')
    }
  }

  if (!isOpen) return null

  return (
    <>
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
        <div className="relative w-full max-w-md rounded-lg bg-white p-6 shadow-lg">
          <button
            onClick={onClose}
            className="absolute right-4 top-4 text-gray-500 hover:text-gray-700 focus:outline-none"
          >
            <FontAwesomeIcon icon={faTimes} size="lg" />
          </button>
          {/* <h3 className="text-2xl font-bold text-gray-800 mb-6 border-b-2 border-gray-200 pb-2">Add Health Camp</h3> */}
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-700"
              >
                {' '}
                HealthCamp Name:
              </label>
              <input
                id="name"
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
            <div>
              <label
                htmlFor="location"
                className="block text-sm font-medium text-gray-700"
              >
                Location:
              </label>
              <input
                id="location"
                type="text"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
                required
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
            <div>
              <label
                htmlFor="date"
                className="block text-sm font-medium text-gray-700"
              >
                Date:
              </label>
              <input
                id="date"
                type="datetime-local"
                value={date}
                onChange={(e) => setDate(e.target.value)}
                required
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
            <div className="space-y-4">
              <label className="block text-sm font-medium text-gray-700">
                Items:
              </label>
              {items.map((item, index) => (
                <div key={index} className="rounded-md border bg-gray-50 p-4">
                  <div>
                    <label
                      htmlFor={`itemName-${index}`}
                      className="block text-sm font-medium text-gray-700"
                    >
                      Item Name:
                    </label>
                    <input
                      id={`itemName-${index}`}
                      name="itemName"
                      type="text"
                      value={item.itemName}
                      onChange={(e) => handleItemChange(index, e)}
                      required
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor={`quantity-${index}`}
                      className="block text-sm font-medium text-gray-700"
                    >
                      Quantity:
                    </label>
                    <input
                      id={`quantity-${index}`}
                      name="quantity"
                      type="number"
                      value={item.quantity}
                      onChange={(e) => handleItemChange(index, e)}
                      required
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor={`unit-${index}`}
                      className="block text-sm font-medium text-gray-700"
                    >
                      Unit:
                    </label>
                    <input
                      id={`unit-${index}`}
                      name="unit"
                      type="text"
                      value={item.unit}
                      onChange={(e) => handleItemChange(index, e)}
                      required
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor={`specifications-${index}`}
                      className="block text-sm font-medium text-gray-700"
                    >
                      Specifications:
                    </label>
                    <input
                      id={`specifications-${index}`}
                      name="specifications"
                      type="text"
                      value={item.specifications}
                      onChange={(e) => handleItemChange(index, e)}
                      required
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                    />
                  </div>
                  <button
                    type="button"
                    onClick={() => handleRemoveItem(index)}
                    className="mt-2 text-red-500 hover:text-red-700"
                  >
                    Remove Item
                  </button>
                </div>
              ))}
              <button
                type="button"
                onClick={handleAddItem}
                className="rounded-md bg-brandPrimary px-4 py-2 text-white hover:bg-brandSecondary"
              >
                Add Another Item
              </button>
            </div>
            <div>
              <label
                htmlFor="notes"
                className="block text-sm font-medium text-gray-700"
              >
                Notes:
              </label>
              <textarea
                id="notes"
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
            <button
              type="submit"
              className="w-full rounded-md bg-brandSecondary px-4 py-2 text-white hover:bg-brandPrimary focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
            >
              Add Health Camp
            </button>
          </form>
        </div>
      </div>
      <ToastContainer />
    </>
  )
}

export default HealthCampModal
