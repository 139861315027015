import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { addIssuedItem } from '../../actions/issuedItemActions'

const ItemModal = ({ isOpen, onClose }) => {
  const dispatch = useDispatch()

  const [name, setName] = useState('')
  const [type, setType] = useState('')
  const [status, setStatus] = useState('')
  const [priorIssues, setPriorIssues] = useState('')
  const [issue, setIssue] = useState('')
  const [issuedDate, setIssuedDate] = useState('')
  const [lastCheckedDate, setLastCheckedDate] = useState('')
  const [repairShop, setRepairShop] = useState('')
  const [quotation, setQuotation] = useState('')
  const [repairStatus, setRepairStatus] = useState('')
  const [repairDate, setRepairDate] = useState('')
  const [issuedTo, setIssuedTo] = useState('')

  const handleSubmit = async (e) => {
    e.preventDefault()
    const itemData = {
      name,
      type,
      status,
      priorIssues: priorIssues.split(','),
      issue,
      issuedDate,
      lastCheckedDate,
      repair: {
        repairShop,
        quotation: parseFloat(quotation),
        repairStatus,
        repairDate,
      },
      issuedTo,
    }
    try {
      await dispatch(addIssuedItem(itemData))
      toast.success('Item added successfully')
      onClose()
    } catch (error) {
      toast.error('Failed to add item')
    }
  }

  if (!isOpen) return null

  return (
    <>
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
        <div className="relative w-full max-w-lg rounded-lg bg-white p-6 shadow-lg">
          <button
            onClick={onClose}
            className="absolute right-4 top-4 text-gray-500 hover:text-gray-700 focus:outline-none"
          >
            <FontAwesomeIcon icon={faTimes} size="lg" />
          </button>
          {/* <h3 className="text-2xl font-bold text-gray-800 mb-6 border-b-2 border-gray-200 pb-2">Add Item</h3> */}
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-700"
              >
                Name <span className="text-red-500">*</span>:
              </label>
              <input
                id="name"
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Enter item name"
                required
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
            <div>
              <label
                htmlFor="type"
                className="block text-sm font-medium text-gray-700"
              >
                Type <span className="text-red-500">*</span>:
              </label>
              <input
                id="type"
                type="text"
                value={type}
                onChange={(e) => setType(e.target.value)}
                placeholder="Enter item type"
                required
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
            <div>
              <label
                htmlFor="status"
                className="block text-sm font-medium text-gray-700"
              >
                Status <span className="text-red-500">*</span>:
              </label>
              <input
                id="status"
                type="text"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                placeholder="Enter item status"
                required
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
            <div>
              <label
                htmlFor="priorIssues"
                className="block text-sm font-medium text-gray-700"
              >
                Prior Issues (comma separated):
              </label>
              <input
                id="priorIssues"
                type="text"
                value={priorIssues}
                onChange={(e) => setPriorIssues(e.target.value)}
                placeholder="e.g., Issue1, Issue2"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
            <div>
              <label
                htmlFor="issue"
                className="block text-sm font-medium text-gray-700"
              >
                Issue:
              </label>
              <input
                id="issue"
                type="text"
                value={issue}
                onChange={(e) => setIssue(e.target.value)}
                placeholder="Describe the issue"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
            <div>
              <label
                htmlFor="issuedDate"
                className="block text-sm font-medium text-gray-700"
              >
                Issued Date <span className="text-red-500">*</span>:
              </label>
              <input
                id="issuedDate"
                type="datetime-local"
                value={issuedDate}
                onChange={(e) => setIssuedDate(e.target.value)}
                placeholder="Select issued date"
                required
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
            <div>
              <label
                htmlFor="lastCheckedDate"
                className="block text-sm font-medium text-gray-700"
              >
                Last Checked Date:
              </label>
              <input
                id="lastCheckedDate"
                type="datetime-local"
                value={lastCheckedDate}
                onChange={(e) => setLastCheckedDate(e.target.value)}
                placeholder="Select last checked date"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
            <div>
              <label
                htmlFor="repairShop"
                className="block text-sm font-medium text-gray-700"
              >
                Repair Shop:
              </label>
              <input
                id="repairShop"
                type="text"
                value={repairShop}
                onChange={(e) => setRepairShop(e.target.value)}
                placeholder="Enter repair shop name"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
            <div>
              <label
                htmlFor="quotation"
                className="block text-sm font-medium text-gray-700"
              >
                Quotation:
              </label>
              <input
                id="quotation"
                type="number"
                step="0.01"
                value={quotation}
                onChange={(e) => setQuotation(e.target.value)}
                placeholder="Enter quotation amount"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
            <div>
              <label
                htmlFor="repairStatus"
                className="block text-sm font-medium text-gray-700"
              >
                Repair Status:
              </label>
              <input
                id="repairStatus"
                type="text"
                value={repairStatus}
                onChange={(e) => setRepairStatus(e.target.value)}
                placeholder="Enter repair status"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
            <div>
              <label
                htmlFor="repairDate"
                className="block text-sm font-medium text-gray-700"
              >
                Repair Date:
              </label>
              <input
                id="repairDate"
                type="datetime-local"
                value={repairDate}
                onChange={(e) => setRepairDate(e.target.value)}
                placeholder="Select repair date"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
            <div>
              <label
                htmlFor="issuedTo"
                className="block text-sm font-medium text-gray-700"
              >
                Issued To <span className="text-red-500">*</span>:
              </label>
              <input
                id="issuedTo"
                type="email"
                value={issuedTo}
                onChange={(e) => setIssuedTo(e.target.value)}
                placeholder="Enter issued employee's email"
                required
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
            <button
              type="submit"
              className="w-full rounded-md bg-brandSecondary px-4 py-2 font-semibold text-white shadow-sm hover:bg-brandPrimary focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              Submit
            </button>
          </form>
          <ToastContainer />
        </div>
      </div>
    </>
  )
}

export default ItemModal
