export const ADD_EMPLOYEE_REQUEST = 'ADD_EMPLOYEE_REQUEST'
export const ADD_EMPLOYEE_SUCCESS = 'ADD_EMPLOYEE_SUCCESS'
export const ADD_EMPLOYEE_FAILURE = 'ADD_EMPLOYEE_FAILURE'

export const UPDATE_EMPLOYEE_REQUEST = 'UPDATE_EMPLOYEE_REQUEST'
export const UPDATE_EMPLOYEE_SUCCESS = 'UPDATE_EMPLOYEE_SUCCESS'
export const UPDATE_EMPLOYEE_FAILURE = 'UPDATE_EMPLOYEE_FAILURE'

export const DELETE_EMPLOYEE_REQUEST = 'DELETE_EMPLOYEE_REQUEST'
export const DELETE_EMPLOYEE_SUCCESS = 'DELETE_EMPLOYEE_SUCCESS'
export const DELETE_EMPLOYEE_FAILURE = 'DELETE_EMPLOYEE_FAILURE'

export const GET_ALL_EMPLOYEES_REQUEST = 'GET_ALL_EMPLOYEES_REQUEST'
export const GET_ALL_EMPLOYEES_SUCCESS = 'GET_ALL_EMPLOYEES_SUCCESS'
export const GET_ALL_EMPLOYEES_FAILURE = 'GET_ALL_EMPLOYEES_FAILURE'
