import axios from 'axios'
import {
  ADD_PURCHASE_ORDER_REQUEST,
  ADD_PURCHASE_ORDER_SUCCESS,
  ADD_PURCHASE_ORDER_FAILURE,
  EDIT_PURCHASE_ORDER_REQUEST,
  EDIT_PURCHASE_ORDER_SUCCESS,
  EDIT_PURCHASE_ORDER_FAILURE,
  DELETE_PURCHASE_ORDER_REQUEST,
  DELETE_PURCHASE_ORDER_SUCCESS,
  DELETE_PURCHASE_ORDER_FAILURE,
  GET_ALL_PURCHASE_ORDERS_REQUEST,
  GET_ALL_PURCHASE_ORDERS_SUCCESS,
  GET_ALL_PURCHASE_ORDERS_FAILURE,
  SEARCH_PURCHASE_ORDER_REQUEST,
  SEARCH_PURCHASE_ORDER_SUCCESS,
  SEARCH_PURCHASE_ORDER_FAILURE,
} from '../constants/purchaseOrderConstants'
import { API_URL, API_KEY } from '../config/config'
import { toast } from 'react-toastify'

const axiosConfig = {
  headers: {
    'X-API-KEY': API_KEY,
    'Content-Type': 'multipart/form-data',
  },
}

// Add Purchase Order
export const addPurchaseOrder = (purchaseOrderData) => async (dispatch) => {
  dispatch({ type: ADD_PURCHASE_ORDER_REQUEST })
  try {
    const res = await axios.post(
      `${API_URL}/purchase_orders/new`,
      purchaseOrderData,
      axiosConfig
    )
    dispatch({
      type: ADD_PURCHASE_ORDER_SUCCESS,
      payload: res.data,
    })
    toast.success('Purchase order added successfully')
  } catch (error) {
    dispatch({
      type: ADD_PURCHASE_ORDER_FAILURE,
      payload: error.response.data,
    })
    toast.error('Failed to add purchase order')
  }
}

// Edit Purchase Order
export const editPurchaseOrder =
  (purchaseOrderId, purchaseOrderData) => async (dispatch) => {
    dispatch({ type: EDIT_PURCHASE_ORDER_REQUEST })
    try {
      const res = await axios.put(
        `${API_URL}/purchase_orders/update/${purchaseOrderId}`,
        purchaseOrderData,
        axiosConfig
      )
      dispatch({
        type: EDIT_PURCHASE_ORDER_SUCCESS,
        payload: res.data,
      })
      toast.success('Purchase order updated successfully')
    } catch (error) {
      dispatch({
        type: EDIT_PURCHASE_ORDER_FAILURE,
        payload: error.response.data,
      })
      toast.error('Failed to update purchase order')
    }
  }

// Delete Purchase Order
export const deletePurchaseOrder = (purchaseOrderId) => async (dispatch) => {
  dispatch({ type: DELETE_PURCHASE_ORDER_REQUEST })
  try {
    await axios.delete(
      `${API_URL}/purchase_orders/${purchaseOrderId}`,
      axiosConfig
    )
    dispatch({
      type: DELETE_PURCHASE_ORDER_SUCCESS,
      payload: purchaseOrderId,
    })
    toast.success('Purchase order deleted successfully')
  } catch (error) {
    dispatch({
      type: DELETE_PURCHASE_ORDER_FAILURE,
      payload: error.response.data,
    })
    toast.error('Failed to delete purchase order')
  }
}

// Get All Purchase Orders
export const getAllPurchaseOrders =
  ({ page = 1, size = 10 } = {}) =>
  async (dispatch) => {
    dispatch({ type: GET_ALL_PURCHASE_ORDERS_REQUEST })
    try {
      const { data } = await axios.get(`${API_URL}/purchase_orders/all`, {
        params: { page, size },
        ...axiosConfig,
      })
      //   console.log('API Response Data:', data); // Add this log
      dispatch({
        type: GET_ALL_PURCHASE_ORDERS_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: GET_ALL_PURCHASE_ORDERS_FAILURE,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

// Search Purchase Order
export const searchPurchaseOrder = (query) => async (dispatch) => {
  dispatch({ type: SEARCH_PURCHASE_ORDER_REQUEST })
  try {
    const res = await axios.get(`${API_URL}/purchase_orders/search`, {
      params: { query },
      ...axiosConfig,
    })
    dispatch({
      type: SEARCH_PURCHASE_ORDER_SUCCESS,
      payload: res.data,
    })
  } catch (error) {
    dispatch({
      type: SEARCH_PURCHASE_ORDER_FAILURE,
      payload: error.response.data,
    })
  }
}
