import {
  ADD_INVENTORY_REQUEST,
  ADD_INVENTORY_SUCCESS,
  ADD_INVENTORY_FAILURE,
  EDIT_INVENTORY_REQUEST,
  EDIT_INVENTORY_SUCCESS,
  EDIT_INVENTORY_FAILURE,
  DELETE_INVENTORY_REQUEST,
  DELETE_INVENTORY_SUCCESS,
  DELETE_INVENTORY_FAILURE,
  GET_ALL_INVENTORY_REQUEST,
  GET_ALL_INVENTORY_SUCCESS,
  GET_ALL_INVENTORY_FAILURE,
  SEARCH_INVENTORY_REQUEST,
  SEARCH_INVENTORY_SUCCESS,
  SEARCH_INVENTORY_FAILURE,
} from '../constants/inventoryConstants'
import { toast } from 'react-toastify'

const initialState = {
  items: [],
  metadata: {},
  loading: false,
  errors: {},
}

export default function inventoryReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_INVENTORY_REQUEST:
    case EDIT_INVENTORY_REQUEST:
    case DELETE_INVENTORY_REQUEST:
    case GET_ALL_INVENTORY_REQUEST:
    case SEARCH_INVENTORY_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case ADD_INVENTORY_SUCCESS:
      toast.success('Inventory item added successfully')
      return {
        ...state,
        items: [...state.items, action.payload],
        loading: false,
      }
    case EDIT_INVENTORY_SUCCESS:
      toast.success('Inventory item updated successfully')
      return {
        ...state,
        items: state.items.map((item) =>
          item._id === action.payload._id ? action.payload : item
        ),
        loading: false,
      }
    case DELETE_INVENTORY_SUCCESS:
      toast.success('Inventory item deleted successfully')
      return {
        ...state,
        items: state.items.filter((item) => item._id !== action.payload),
        loading: false,
      }
    case GET_ALL_INVENTORY_SUCCESS:
      // console.log('Reducer Data:', action.payload); // Add this log
      return {
        ...state,
        items: action.payload.items,
        metadata: action.payload.metadata,
        loading: false,
      }
    case SEARCH_INVENTORY_SUCCESS:
      return {
        ...state,
        items: action.payload,
        loading: false,
      }
    case ADD_INVENTORY_FAILURE:
      toast.error(`Failed to add inventory item: ${action.payload.message}`)
      return {
        ...state,
        errors: action.payload,
        loading: false,
      }
    case EDIT_INVENTORY_FAILURE:
      toast.error(`Failed to update inventory item: ${action.payload.message}`)
      return {
        ...state,
        errors: action.payload,
        loading: false,
      }
    case DELETE_INVENTORY_FAILURE:
      toast.error(`Failed to delete inventory item: ${action.payload.message}`)
      return {
        ...state,
        errors: action.payload,
        loading: false,
      }
    case GET_ALL_INVENTORY_FAILURE:
      toast.error(`Failed to fetch inventory items: ${action.payload.message}`)
      return {
        ...state,
        errors: action.payload,
        loading: false,
      }
    case SEARCH_INVENTORY_FAILURE:
      toast.error(`Failed to search inventory item: ${action.payload.message}`)
      return {
        ...state,
        errors: action.payload,
        loading: false,
      }
    default:
      return state
  }
}
