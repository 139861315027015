import axios from 'axios'
import {
  ADD_INVENTORY_REQUEST,
  ADD_INVENTORY_SUCCESS,
  ADD_INVENTORY_FAILURE,
  EDIT_INVENTORY_REQUEST,
  EDIT_INVENTORY_SUCCESS,
  EDIT_INVENTORY_FAILURE,
  DELETE_INVENTORY_REQUEST,
  DELETE_INVENTORY_SUCCESS,
  DELETE_INVENTORY_FAILURE,
  GET_ALL_INVENTORY_REQUEST,
  GET_ALL_INVENTORY_SUCCESS,
  GET_ALL_INVENTORY_FAILURE,
  SEARCH_INVENTORY_REQUEST,
  SEARCH_INVENTORY_SUCCESS,
  SEARCH_INVENTORY_FAILURE,
} from '../constants/inventoryConstants'
import { API_URL, API_KEY } from '../config/config'
import { toast } from 'react-toastify'

const axiosConfig = {
  headers: {
    'X-API-KEY': API_KEY,
  },
}

// Add Inventory Item
export const addInventoryItem = (inventoryData) => async (dispatch) => {
  dispatch({ type: ADD_INVENTORY_REQUEST })
  try {
    const res = await axios.post(
      `${API_URL}/inventories/new_inventory`,
      inventoryData,
      axiosConfig
    )
    dispatch({
      type: ADD_INVENTORY_SUCCESS,
      payload: res.data,
    })
    toast.success('Inventory item added successfully')
  } catch (error) {
    dispatch({
      type: ADD_INVENTORY_FAILURE,
      payload: error.response.data,
    })
    toast.error('Failed to add inventory item')
  }
}

// Edit Inventory Item
export const editInventoryItem =
  (inventoryId, inventoryData) => async (dispatch) => {
    dispatch({ type: EDIT_INVENTORY_REQUEST })
    try {
      const res = await axios.put(
        `${API_URL}/inventories/inventory/${inventoryId}`,
        inventoryData,
        axiosConfig
      )
      dispatch({
        type: EDIT_INVENTORY_SUCCESS,
        payload: res.data,
      })
      toast.success('Inventory item updated successfully')
    } catch (error) {
      dispatch({
        type: EDIT_INVENTORY_FAILURE,
        payload: error.response.data,
      })
      toast.error('Failed to update inventory item')
    }
  }

// Delete Inventory Item
export const deleteInventoryItem = (inventoryId) => async (dispatch) => {
  dispatch({ type: DELETE_INVENTORY_REQUEST })
  try {
    await axios.delete(
      `${API_URL}/inventories/inventory/${inventoryId}`,
      axiosConfig
    )
    dispatch({
      type: DELETE_INVENTORY_SUCCESS,
      payload: inventoryId,
    })
    toast.success('Inventory item deleted successfully')
  } catch (error) {
    dispatch({
      type: DELETE_INVENTORY_FAILURE,
      payload: error.response.data,
    })
    toast.error('Failed to delete inventory item')
  }
}

// Get All Inventory Items
export const getAllInventoryItems =
  ({ page = 1, size = 10 } = {}) =>
  async (dispatch) => {
    dispatch({ type: GET_ALL_INVENTORY_REQUEST })
    try {
      const { data } = await axios.get(`${API_URL}/inventories/all`, {
        params: { page, size },
        ...axiosConfig,
      })

      console.log('API Response Data:', data) // Add this log
      dispatch({
        type: GET_ALL_INVENTORY_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: GET_ALL_INVENTORY_FAILURE,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

// Search Inventory Items
export const searchInventoryItem = (query) => async (dispatch) => {
  dispatch({ type: SEARCH_INVENTORY_REQUEST })
  try {
    const res = await axios.get(`${API_URL}/inventories/search`, {
      params: { query },
      ...axiosConfig,
    })
    dispatch({
      type: SEARCH_INVENTORY_SUCCESS,
      payload: res.data,
    })
  } catch (error) {
    dispatch({
      type: SEARCH_INVENTORY_FAILURE,
      payload: error.response.data,
    })
  }
}
