// reducers/employeeReducer.js
import {
  ADD_EMPLOYEE_REQUEST,
  ADD_EMPLOYEE_SUCCESS,
  ADD_EMPLOYEE_FAILURE,
  UPDATE_EMPLOYEE_REQUEST,
  UPDATE_EMPLOYEE_SUCCESS,
  UPDATE_EMPLOYEE_FAILURE,
  DELETE_EMPLOYEE_REQUEST,
  DELETE_EMPLOYEE_SUCCESS,
  DELETE_EMPLOYEE_FAILURE,
  GET_ALL_EMPLOYEES_REQUEST,
  GET_ALL_EMPLOYEES_SUCCESS,
  GET_ALL_EMPLOYEES_FAILURE,
} from '../constants/employeeConstants'
import { toast } from 'react-toastify'

const initialState = {
  employees: [],
  metadata: {},
  loading: false,
  errors: {},
}

export default function employeeReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_EMPLOYEE_REQUEST:
    case UPDATE_EMPLOYEE_REQUEST:
    case DELETE_EMPLOYEE_REQUEST:
    case GET_ALL_EMPLOYEES_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case ADD_EMPLOYEE_SUCCESS:
      toast.success('Employee added successfully')
      return {
        ...state,
        employees: [...state.employees, action.payload],
        loading: false,
      }
    case UPDATE_EMPLOYEE_SUCCESS:
      toast.success('Employee updated successfully')
      return {
        ...state,
        employees: state.employees.map((employee) =>
          employee._id === action.payload._id ? action.payload : employee
        ),
        loading: false,
      }
    case DELETE_EMPLOYEE_SUCCESS:
      toast.success('Employee deleted successfully')
      return {
        ...state,
        employees: state.employees.filter(
          (employee) => employee._id !== action.payload
        ),
        loading: false,
      }
    case GET_ALL_EMPLOYEES_SUCCESS:
      // console.log(state.employees, "EmployeeS ")
      return {
        ...state,
        employees: action.payload.data,
        metadata: action.payload.metadata,
        loading: false,
      }
    case ADD_EMPLOYEE_FAILURE:
      toast.error(`Failed to add employee: ${action.payload.message}`)
      return {
        ...state,
        errors: action.payload,
        loading: false,
      }
    case UPDATE_EMPLOYEE_FAILURE:
      toast.error(`Failed to update employee: ${action.payload.message}`)
      return {
        ...state,
        errors: action.payload,
        loading: false,
      }
    case DELETE_EMPLOYEE_FAILURE:
      toast.error(`Failed to delete employee: ${action.payload.message}`)
      return {
        ...state,
        errors: action.payload,
        loading: false,
      }
    case GET_ALL_EMPLOYEES_FAILURE:
      toast.error(`Failed to fetch employees: ${action.payload.message}`)
      return {
        ...state,
        errors: action.payload,
        loading: false,
      }
    default:
      return state
  }
}
