// actions/supplierActions.js
import axios from 'axios'
import {
  ADD_SUPPLIER_REQUEST,
  ADD_SUPPLIER_SUCCESS,
  ADD_SUPPLIER_FAILURE,
  EDIT_SUPPLIER_REQUEST,
  EDIT_SUPPLIER_SUCCESS,
  EDIT_SUPPLIER_FAILURE,
  DELETE_SUPPLIER_REQUEST,
  DELETE_SUPPLIER_SUCCESS,
  DELETE_SUPPLIER_FAILURE,
  GET_ALL_SUPPLIERS_REQUEST,
  GET_ALL_SUPPLIERS_SUCCESS,
  GET_ALL_SUPPLIERS_FAILURE,
  SEARCH_SUPPLIER_REQUEST,
  SEARCH_SUPPLIER_SUCCESS,
  SEARCH_SUPPLIER_FAILURE,
} from '../constants/supplierConstants'
import { API_URL, API_KEY } from '../config/config'
import { toast } from 'react-toastify'

const axiosConfig = {
  headers: {
    'X-API-KEY': API_KEY,
  },
}

// Add Supplier
export const addSupplier = (supplierData) => async (dispatch) => {
  dispatch({ type: ADD_SUPPLIER_REQUEST })
  try {
    const res = await axios.post(
      `${API_URL}/suppliers/supplier`,
      supplierData,
      axiosConfig
    )
    dispatch({
      type: ADD_SUPPLIER_SUCCESS,
      payload: res.data,
    })
    toast.success('Supplier added successfully')
  } catch (error) {
    dispatch({
      type: ADD_SUPPLIER_FAILURE,
      payload: error.response.data,
    })
    toast.error('Failed to add supplier')
  }
}

// Edit Supplier
export const editSupplier = (supplierId, supplierData) => async (dispatch) => {
  dispatch({ type: EDIT_SUPPLIER_REQUEST })
  try {
    const res = await axios.put(
      `${API_URL}/suppliers/supplier/${supplierId}`,
      supplierData,
      axiosConfig
    )
    dispatch({
      type: EDIT_SUPPLIER_SUCCESS,
      payload: res.data,
    })
    toast.success('Supplier updated successfully')
  } catch (error) {
    dispatch({
      type: EDIT_SUPPLIER_FAILURE,
      payload: error.response.data,
    })
    toast.error('Failed to update supplier')
  }
}

// Delete Supplier
export const deleteSupplier = (supplierId) => async (dispatch) => {
  dispatch({ type: DELETE_SUPPLIER_REQUEST })
  try {
    await axios.delete(
      `${API_URL}/suppliers/supplier/${supplierId}`,
      axiosConfig
    )
    dispatch({
      type: DELETE_SUPPLIER_SUCCESS,
      payload: supplierId,
    })
    toast.success('Supplier deleted successfully')
  } catch (error) {
    dispatch({
      type: DELETE_SUPPLIER_FAILURE,
      payload: error.response.data,
    })
    toast.error('Failed to delete supplier')
  }
}

// Get All Suppliers
export const getAllSuppliers =
  ({ page = 1, size = 10 } = {}) =>
  async (dispatch) => {
    dispatch({ type: GET_ALL_SUPPLIERS_REQUEST })
    try {
      const { data } = await axios.get(`${API_URL}/suppliers/suppliers`, {
        params: { page, size },
        ...axiosConfig,
      })
      dispatch({
        type: GET_ALL_SUPPLIERS_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: GET_ALL_SUPPLIERS_FAILURE,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

// Search Supplier
export const searchSupplier = (query) => async (dispatch) => {
  dispatch({ type: SEARCH_SUPPLIER_REQUEST })
  try {
    const res = await axios.get(`${API_URL}/suppliers/search`, {
      params: { query },
      ...axiosConfig,
    })
    dispatch({
      type: SEARCH_SUPPLIER_SUCCESS,
      payload: res.data,
    })
  } catch (error) {
    dispatch({
      type: SEARCH_SUPPLIER_FAILURE,
      payload: error.response.data,
    })
  }
}
