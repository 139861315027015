import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { updateQuotation } from '../../actions/rfqActions'

const EditQuotationModal = ({ isOpen, onClose, quotation }) => {
  const dispatch = useDispatch()

  const [phoneNumber, setphoneNumber] = useState('')
  const [price, setPrice] = useState('')
  const [validUntil, setValidUntil] = useState('')

  useEffect(() => {
    if (quotation) {
      // Ensure that quotation.supplier is not null and has phoneNumber
      setphoneNumber(quotation.supplier?.phoneNumber || '')
      setPrice(quotation.price || '')
      // Format the date to yyyy-MM-dd
      const formattedDate = new Date(quotation.validUntil)
        .toISOString()
        .split('T')[0]
      setValidUntil(formattedDate || '')
    }
  }, [quotation])

  const handleSubmit = async (e) => {
    e.preventDefault()
    const updatedQuotation = {
      phoneNumber,
      price,
      validUntil,
    }

    try {
      await dispatch(updateQuotation(quotation._id, updatedQuotation))
      toast.success('Quotation updated successfully')
      onClose()
    } catch (error) {
      toast.error('Failed to update quotation')
    }
  }

  if (!isOpen) return null

  return (
    <>
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
        <div className="relative w-full max-w-md rounded-lg bg-white p-6 shadow-lg">
          <button
            onClick={onClose}
            className="absolute right-4 top-4 text-gray-500 hover:text-gray-700 focus:outline-none"
          >
            <FontAwesomeIcon icon={faTimes} size="lg" />
          </button>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label
                htmlFor="phoneNumber"
                className="block text-sm font-medium text-gray-700"
              >
                Supplier Phone Number:
              </label>
              <input
                id="phoneNumber"
                type="text"
                value={phoneNumber}
                onChange={(e) => setphoneNumber(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
            <div>
              <label
                htmlFor="price"
                className="block text-sm font-medium text-gray-700"
              >
                Price:
              </label>
              <input
                id="price"
                type="number"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
            <div>
              <label
                htmlFor="validUntil"
                className="block text-sm font-medium text-gray-700"
              >
                Valid Until:
              </label>
              <input
                id="validUntil"
                type="date"
                value={validUntil}
                onChange={(e) => setValidUntil(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
            <button
              type="submit"
              className="w-full rounded-md bg-brandSecondary px-4 py-2 text-white hover:bg-brandPrimary focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
            >
              Update Quotation
            </button>
          </form>
        </div>
      </div>
      <ToastContainer />
    </>
  )
}

export default EditQuotationModal
