import {
  ADD_PURCHASE_ORDER_REQUEST,
  ADD_PURCHASE_ORDER_SUCCESS,
  ADD_PURCHASE_ORDER_FAILURE,
  EDIT_PURCHASE_ORDER_REQUEST,
  EDIT_PURCHASE_ORDER_SUCCESS,
  EDIT_PURCHASE_ORDER_FAILURE,
  DELETE_PURCHASE_ORDER_REQUEST,
  DELETE_PURCHASE_ORDER_SUCCESS,
  DELETE_PURCHASE_ORDER_FAILURE,
  GET_ALL_PURCHASE_ORDERS_REQUEST,
  GET_ALL_PURCHASE_ORDERS_SUCCESS,
  GET_ALL_PURCHASE_ORDERS_FAILURE,
  SEARCH_PURCHASE_ORDER_REQUEST,
  SEARCH_PURCHASE_ORDER_SUCCESS,
  SEARCH_PURCHASE_ORDER_FAILURE,
} from '../constants/purchaseOrderConstants'
import { toast } from 'react-toastify'

const initialState = {
  purchaseOrders: [],
  metadata: {},
  loading: false,
  errors: {},
}

export default function purchaseOrderReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_PURCHASE_ORDER_REQUEST:
    case EDIT_PURCHASE_ORDER_REQUEST:
    case DELETE_PURCHASE_ORDER_REQUEST:
    case GET_ALL_PURCHASE_ORDERS_REQUEST:
    case SEARCH_PURCHASE_ORDER_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case ADD_PURCHASE_ORDER_SUCCESS:
      toast.success('Purchase order added successfully')
      return {
        ...state,
        purchaseOrders: [...state.purchaseOrders, action.payload],
        loading: false,
      }
    case EDIT_PURCHASE_ORDER_SUCCESS:
      toast.success('Purchase order updated successfully')
      return {
        ...state,
        purchaseOrders: state.purchaseOrders.map((order) =>
          order._id === action.payload._id ? action.payload : order
        ),
        loading: false,
      }
    case DELETE_PURCHASE_ORDER_SUCCESS:
      toast.success('Purchase order deleted successfully')
      return {
        ...state,
        purchaseOrders: state.purchaseOrders.filter(
          (order) => order._id !== action.payload
        ),
        loading: false,
      }
    case GET_ALL_PURCHASE_ORDERS_SUCCESS:
      // console.log('Reducer Data:', action.payload); // Add this log
      return {
        ...state,
        purchaseOrders: action.payload.purchaseOrders,
        metadata: action.payload.metadata,
        loading: false,
      }
    case SEARCH_PURCHASE_ORDER_SUCCESS:
      return {
        ...state,
        purchaseOrders: action.payload,
        loading: false,
      }
    case ADD_PURCHASE_ORDER_FAILURE:
      toast.error(`Failed to add purchase order: ${action.payload.message}`)
      return {
        ...state,
        errors: action.payload,
        loading: false,
      }
    case EDIT_PURCHASE_ORDER_FAILURE:
      toast.error(`Failed to update purchase order: ${action.payload.message}`)
      return {
        ...state,
        errors: action.payload,
        loading: false,
      }
    case DELETE_PURCHASE_ORDER_FAILURE:
      toast.error(`Failed to delete purchase order: ${action.payload.message}`)
      return {
        ...state,
        errors: action.payload,
        loading: false,
      }
    case GET_ALL_PURCHASE_ORDERS_FAILURE:
      toast.error(`Failed to fetch purchase orders: ${action.payload.message}`)
      return {
        ...state,
        errors: action.payload,
        loading: false,
      }
    case SEARCH_PURCHASE_ORDER_FAILURE:
      toast.error(`Failed to search purchase order: ${action.payload.message}`)
      return {
        ...state,
        errors: action.payload,
        loading: false,
      }
    default:
      return state
  }
}
