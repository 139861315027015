import React from 'react'
import InventoryList from '../Inventory/InventoryList'

function InventoryPage() {
  return (
    <div>
      <InventoryList />
    </div>
  )
}

export default InventoryPage
