import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteQuotation, getQuotations } from '../../actions/rfqActions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEllipsisV,
  faEdit,
  faTrash,
  faPlus,
  faSearch,
  faCopy,
} from '@fortawesome/free-solid-svg-icons'
import AddQuotationModal from './QuotationModal'
import WarningQuotationModal from './WarningQuotationModal'
import EditQuotationModal from './EditQuotationModal'

const QuotationList = () => {
  const dispatch = useDispatch()
  const { quotations, loading, metadata } = useSelector((state) => state.rfqs)

  const [currentPage, setCurrentPage] = useState(1)
  const pageSize = 10

  useEffect(() => {
    dispatch(getQuotations({ page: currentPage, size: pageSize }))
  }, [dispatch, currentPage])

  const [menuOpen, setMenuOpen] = useState(null)
  const menuRef = useRef(null)
  const deleteButtonRef = useRef(null)
  const editButtonRef = useRef(null)
  const [modalOpen, setModalOpen] = useState(false)
  const [warningModalOpen, setWarningModalOpen] = useState(false)
  const [quotationToDelete, setQuotationToDelete] = useState(null)
  const [searchQuery, setSearchQuery] = useState('')
  const [editModalOpen, setEditModalOpen] = useState(false)
  const [quotationToEdit, setQuotationToEdit] = useState(null)

  const toggleMenu = (id) => {
    setMenuOpen(menuOpen === id ? null : id)
  }

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber)
  }

  const handleEdit = (quotation) => {
    setQuotationToEdit(quotation)
    setEditModalOpen(true)
    setMenuOpen(null)
  }

  const handleDelete = (id) => {
    setQuotationToDelete(id)
    setWarningModalOpen(true)
  }

  const confirmDelete = () => {
    if (quotationToDelete) {
      dispatch(deleteQuotation(quotationToDelete))
      setQuotationToDelete(null)
      setWarningModalOpen(false)
    }
  }

  const cancelDelete = () => {
    setQuotationToDelete(null)
    setWarningModalOpen(false)
  }

  const copyToClipboard = (id) => {
    navigator.clipboard
      .writeText(id)
      .then(() => {
        alert('Quotation ID copied to clipboard!')
      })
      .catch((err) => {
        console.error('Failed to copy text: ', err)
      })
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        deleteButtonRef.current &&
        !deleteButtonRef.current.contains(event.target) &&
        editButtonRef.current &&
        !editButtonRef.current.contains(event.target)
      ) {
        setMenuOpen(null)
        setEditModalOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  if (loading) return <p className="text-center text-lg">Loading...</p>

  return (
    <div className="container mx-auto p-4">
      <div className="mb-4 flex items-center justify-between">
        <button
          onClick={() => setModalOpen(true)}
          className="btn flex items-center rounded bg-brandPrimary px-4 py-2 text-white"
        >
          <FontAwesomeIcon icon={faPlus} className="mr-2" /> Add Quotation
        </button>
        <div className="flex items-center space-x-2">
          <input
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="rounded-lg border border-gray-300 px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <button className="btn flex items-center rounded bg-gray-200 px-4 py-2 text-gray-600 hover:bg-gray-300">
            <FontAwesomeIcon icon={faSearch} className="mr-2" /> Search
          </button>
        </div>
      </div>
      <div className="overflow-x-auto">
        <table className="min-w-full rounded-lg border border-gray-200 bg-white shadow-md">
          <thead className="bg-gray-100 text-left text-gray-600">
            <tr>
              <th className="px-4 py-2">ID</th>
              <th className="px-4 py-2">Supplier Name</th>
              <th className="px-4 py-2">Supplier Phone</th>
              <th className="px-4 py-2">Price</th>
              <th className="px-4 py-2">Valid Until</th>
              <th className="px-4 py-2">Actions</th>
            </tr>
          </thead>
          <tbody>
            {quotations
              .filter(
                (quotation) =>
                  quotation?.price &&
                  quotation?.validUntil &&
                  quotation?.supplier.phoneNumber.includes(
                    searchQuery.toLowerCase()
                  )
              )
              .map((quotation) => (
                <tr key={quotation._id}>
                  <td className="flex items-center px-4 py-2">
                    <span>{quotation._id.substring(0, 6)}</span>
                    <button
                      onClick={() => copyToClipboard(quotation._id)}
                      className="ml-2 text-gray-600 hover:text-gray-900 focus:outline-none"
                    >
                      <FontAwesomeIcon icon={faCopy} />
                    </button>
                  </td>
                  <td className="px-4 py-2">{quotation.supplier.name}</td>
                  <td className="px-4 py-2">
                    {quotation.supplier.phoneNumber}
                  </td>
                  <td className="px-4 py-2">${quotation.price}</td>
                  <td className="px-4 py-2">
                    {new Date(quotation.validUntil).toLocaleDateString()}
                  </td>
                  <td className="px-4 py-2">
                    <div className="relative flex items-center" ref={menuRef}>
                      <button
                        onClick={() => toggleMenu(quotation._id)}
                        className="text-gray-600 hover:text-gray-900 focus:outline-none"
                      >
                        <FontAwesomeIcon icon={faEllipsisV} />
                      </button>
                      {menuOpen === quotation._id && (
                        <div className="absolute right-0 z-10 mt-1 flex items-center space-x-2">
                          <button
                            ref={editButtonRef} // Add ref here
                            onClick={() => handleEdit(quotation)}
                            className="block flex items-center py-2 text-gray-700 hover:bg-gray-100"
                          >
                            <FontAwesomeIcon icon={faEdit} className="ml-1" />
                          </button>
                          <button
                            ref={deleteButtonRef} // Add ref here
                            onClick={() => handleDelete(quotation._id)}
                            className="block flex items-center py-2 text-gray-700 hover:bg-gray-100"
                          >
                            <FontAwesomeIcon
                              icon={faTrash}
                              className="ml-1 text-red-600"
                            />
                          </button>
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <div className="mt-4">
        <nav className="flex justify-between">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className="btn rounded bg-gray-200 px-4 py-2 text-gray-600 hover:bg-gray-300"
          >
            Previous
          </button>
          <span className="text-gray-600">
            Page {currentPage} of {Math.ceil(metadata.total / pageSize)}
          </span>
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage * pageSize >= metadata.total}
            className="btn rounded bg-gray-200 px-4 py-2 text-gray-600 hover:bg-gray-300"
          >
            Next
          </button>
        </nav>
      </div>

      <AddQuotationModal
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
      />
      <EditQuotationModal
        isOpen={editModalOpen}
        onClose={() => setEditModalOpen(false)}
        quotation={quotationToEdit}
      />
      <WarningQuotationModal
        isOpen={warningModalOpen}
        onClose={cancelDelete}
        quotationId={
          quotationToDelete
            ? quotations.find((o) => o._id === quotationToDelete)?._id
            : ''
        }
        onConfirm={confirmDelete}
      />
    </div>
  )
}

export default QuotationList
