import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

const WarningModal = ({ isOpen, onClose, itemName, onConfirm }) => {
  if (!isOpen) return null

  return (
    <>
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
        <div className="relative w-full max-w-md rounded-lg bg-white p-6 shadow-lg">
          <button
            onClick={onClose}
            className="absolute right-4 top-4 text-gray-500 hover:text-gray-700 focus:outline-none"
          >
            <FontAwesomeIcon icon={faTimes} size="lg" />
          </button>
          <p className="mb-4 text-gray-700">
            Are you sure you want to delete this <strong>{itemName}</strong>?
            This action is irreversible.
          </p>
          <div className="flex justify-end space-x-4">
            <button
              onClick={onConfirm}
              className="rounded-md bg-brandPrimary px-4 py-2 text-white hover:bg-brandSecondary focus:outline-none"
            >
              Delete
            </button>
            <button
              onClick={onClose}
              className="rounded-md bg-gray-300 px-4 py-2 text-gray-700 hover:bg-gray-400 focus:outline-none"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default WarningModal
